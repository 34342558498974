import React, { useState } from 'react';
import './styles.scss';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';

function ForgotPasswordPage() {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [code, setCode] = useState('    ');

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    return (
        <div className='forgot-password-page'>
            {step === 1 && (
                <Step1
                    nextStep={nextStep}
                    previousStep={previousStep}
                    token={token}
                    setToken={setToken}
                    code={code}
                    setCode={setCode}
                    email={email}
                    setEmail={setEmail}
                />
            )}
            {step === 2 && (
                <Step2
                    nextStep={nextStep}
                    previousStep={previousStep}
                    token={token}
                    setToken={setToken}
                    code={code}
                    setCode={setCode}
                    email={email}
                    setEmail={setEmail}
                />
            )}
            {step === 3 && (
                <Step3
                    nextStep={nextStep}
                    previousStep={previousStep}
                    token={token}
                    setToken={setToken}
                    code={code}
                    setCode={setCode}
                    email={email}
                    setEmail={setEmail}
                />
            )}
            {step === 4 && <Step4 />}
        </div>
    );
}

export default ForgotPasswordPage;
