import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import config from 'config';
import { IAldermenListItem } from './interface';

function AldermenListItem({ alderman, className }: IAldermenListItem) {
    const { t } = useTranslation();
    return (
        <div className={`aldermen-list-item ${className || ''}`}>
            <div className='aldermen-list-item__img'>
                <img
                    src={`${config.API_URL}/aldermen/images/${alderman.Id}`}
                    alt='Item Img'
                />
            </div>
            <p className='aldermen-list-item__title'>{alderman.Name}</p>
            <p className='aldermen-list-item__description'>
                <span>{t('entity.ENTITY_FIELDS')}</span>: {alderman.Info}
            </p>
        </div>
    );
}

export default AldermenListItem;
