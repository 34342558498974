export const aboutPageContentMotion = {
    initial: { opacity: 0 },
    animate: {
        opacity: [0, 1],
        transition: {
            duration: 0.4,
            ease: 'easeIn',
            delay: 0.3,
        },
    },
};
