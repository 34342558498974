import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SuccessImage from 'assets/images/forms/success.png';
import { motion } from 'framer-motion';
import SiteButton from 'components/SiteButton';
import { forgotPasswordPageContentMotion } from 'containers/ForgotPasswordPage/animation';

function Step4() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <motion.div
            {...forgotPasswordPageContentMotion}
            className='forgot-password-page__success'
        >
            <div className='forgot-password-page__success__password-changed'>
                <div className='forgot-password-page__success__password-changed__header' />
                <div className='forgot-password-page__success__password-changed__body'>
                    <img src={SuccessImage} alt='Success Img' />
                    <p className='forgot-password-page__success__password-changed__body__title'>
                        {t('forgotPassword.SUCCESS_CHANGING_PASSWORD')}
                    </p>
                </div>
                <div className='forgot-password-page__success__password-changed__footer'>
                    <SiteButton
                        text={t('general.LOGIN_INTO_ACCOUNT')}
                        type='primary'
                        className='full-width grey'
                        onClick={() => {
                            navigate('/login');
                        }}
                    />
                </div>
            </div>
        </motion.div>
    );
}

export default Step4;
