import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import PageBanner from 'components/PageBanner';
import Events from './components/Events';

function EventsPage() {
    const { t } = useTranslation();

    return (
        <div className='events-page'>
            <PageBanner
                title={t('menu.WARNINGS')}
                primaryPage={false}
                previous
            />
            <Events />
        </div>
    );
}

export default EventsPage;
