/* eslint-disable react/no-array-index-key */
import React from 'react';
import './styles.scss';
import config from 'config';
import { IPresidentListItem } from './interface';

function PresidentListItem({ president, className }: IPresidentListItem) {
    return (
        <div className={`president-list-item ${className || ''}`}>
            <div className='president-list-item__img'>
                <img
                    src={`${config.API_URL}/presidents/images/${president.Id}`}
                    alt='Item Img'
                />
            </div>
            <p className='president-list-item__title'>{president.Name}</p>
            <div className='president-list-item__description'>
                {president.Info &&
                    president.Info.split('\r\n').map(
                        (paragraph: string, index: number) => {
                            return (
                                <p
                                    className='president-list-item__description__content'
                                    key={index}
                                >
                                    {paragraph}
                                </p>
                            );
                        },
                    )}
            </div>
        </div>
    );
}

export default PresidentListItem;
