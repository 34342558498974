import React, { useContext, useMemo, useState } from 'react';
import { IAccountDTO } from 'DTOModels/accountDTO';
import { useTranslation } from 'react-i18next';
import { axios } from '../startup';

interface IAccountProviderProps {
    children: JSX.Element;
}

interface IAccountContext {
    account?: IAccountDTO;
    setAccount?: React.Dispatch<React.SetStateAction<IAccountDTO | undefined>>;
    setFetchTry?: React.Dispatch<React.SetStateAction<boolean>>;
    fetchAccount?: (callback: (success: boolean) => void) => void;
    fetchTry?: boolean;
}

const AccountContext = React.createContext<IAccountContext>({});

export const useAccount = () => useContext(AccountContext);

function AccountProvider({ children }: IAccountProviderProps) {
    const [account, setAccount] = useState<IAccountDTO>();
    const [fetchTry, setFetchTry] = useState<boolean>(false);
    const { i18n } = useTranslation();
    const value = useMemo<IAccountContext>(
        () => ({
            account,
            setAccount,
            setFetchTry,
            fetchAccount: callback => {
                axios
                    .get('/accounts')
                    .then((res: any) => {
                        setAccount(res.data.account);
                        i18n.changeLanguage(
                            res.data.account.language.toLowerCase(),
                        );
                        callback(true);
                    })
                    .catch(() => callback(false))
                    .finally(() => setFetchTry(true));
            },
            fetchTry,
        }),
        [account, fetchTry],
    );

    return (
        <AccountContext.Provider value={value}>
            {children}
        </AccountContext.Provider>
    );
}

export default AccountProvider;
