import React, { useState } from 'react';
import InfoIcon from 'assets/icons/info_icon.svg';
import { motion } from 'framer-motion';
import './styles.scss';
import { IInformationalPopoverProps } from './interface';
import { informationalPopoverMotion } from './animations';

function InformationalPopover({
    title,
    description,
    info,
}: IInformationalPopoverProps) {
    const [visibility, setVisibility] = useState(false);
    return (
        <div
            className='informational-popover'
            onMouseEnter={() => setVisibility(true)}
            onMouseLeave={() => setVisibility(false)}
        >
            <img src={InfoIcon} alt='Info Icon' />
            {visibility && (
                <motion.div
                    {...informationalPopoverMotion}
                    className='informational-popover__popover'
                >
                    <p className='informational-popover__popover__title'>
                        {title}
                    </p>
                    <p>{description}</p>
                    {info && <p>{info}</p>}
                    <p className='informational-popover__popover__tip' />
                </motion.div>
            )}
        </div>
    );
}

export default InformationalPopover;
