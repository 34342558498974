import React from 'react';
import './styles.scss';
import config from 'config';
import { IDeputiesListItem } from './interface';

function DeputiesListItem({ deputy, className }: IDeputiesListItem) {
    return (
        <div className={`deputies-list-item ${className || ''}`}>
            <div className='deputies-list-item__img'>
                <img
                    src={`${config.API_URL}/deputies/images/${deputy.Id}`}
                    alt='Item Img'
                />
            </div>
            <p className='deputies-list-item__title'>{deputy.Name}</p>
            <p className='deputies-list-item__description'>({deputy.Info})</p>
        </div>
    );
}

export default DeputiesListItem;
