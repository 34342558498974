/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NewOccurrence from 'assets/icons/new_occurrence_icon.svg';
import { motion } from 'framer-motion';
import { useNewNotifications } from 'providers/NewNotificationsProvider';
import { ReactComponent as OccurrenceIcon } from 'assets/icons/occurrence_icon.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/notification_icon.svg';
import { ReactComponent as SettignsIcon } from 'assets/icons/settings_icon.svg';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { IFooterProps } from './interface';
import { NewOccurrenceMotion } from './animation';

const minutes = 5;

function Footer({ tab }: IFooterProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { newNotifications, fetchNewNotifications } = useNewNotifications();
    const [activeTab, setActiveTab] = useState(tab);

    useEffect(() => {
        if (tab) setActiveTab(tab);
    }, [tab]);

    useEffect(() => {
        if (newNotifications) return;

        fetchNewNotifications!(() => {});
    }, [location.pathname]);

    // check every x minutes
    useEffect(() => {
        setInterval(() => {
            fetchNewNotifications!(() => {});
        }, minutes * 60 * 1000);
    }, []);

    return (
        <div className='footer'>
            {location.pathname === '/' && (
                <motion.button
                    type='button'
                    {...NewOccurrenceMotion}
                    onClick={() => {
                        navigate('/new-occurrence');
                    }}
                    className='footer__new-occurrence-button'
                >
                    <img src={NewOccurrence} alt='Occurrence Icon' />
                </motion.button>
            )}
            <button
                className={`footer__tab ${activeTab === 1 ? 'active' : ''}`}
                type='button'
                onClick={() => {
                    navigate('/notifications');
                }}
            >
                <div className='footer__tab__tab-container'>
                    <div className='footer__tab__tab-container__icon'>
                        <NotificationIcon />
                        {newNotifications && (
                            <div className='footer__tab__tab-container__icon__notification' />
                        )}
                    </div>
                    <p>{t('general.NOTIFICATIONS')}</p>
                    <div className='footer__tab__tab-container__marker' />
                </div>
            </button>
            <button
                className={`footer__tab ${activeTab === 2 ? 'active' : ''}`}
                type='button'
                onClick={() => {
                    navigate('/');
                }}
            >
                <div className='footer__tab__tab-container'>
                    <div className='footer__tab__tab-container__icon'>
                        <OccurrenceIcon />
                    </div>
                    <p>{t('occurrences.OCCURRENCES')}</p>
                    <div className='footer__tab__tab-container__marker' />
                </div>
            </button>
            <button
                className={`footer__tab ${activeTab === 3 ? 'active' : ''}`}
                type='button'
                onClick={() => {
                    navigate('/settings');
                }}
            >
                <div className='footer__tab__tab-container'>
                    <div className='footer__tab__tab-container__icon'>
                        <SettignsIcon />
                    </div>
                    <p>{t('general.SETTINGS')}</p>
                    <div className='footer__tab__tab-container__marker' />
                </div>
            </button>
        </div>
    );
}

export default Footer;
