import React from 'react';
import './styles.scss';
import config from 'config';
import { IRentACarListItem } from './interface';

function RentACarListItem({ rentACar }: IRentACarListItem) {
    return (
        <div className='rent-a-car-list-item'>
            <img
                src={`${config.API_URL}/rentacar/images/${rentACar.Id}`}
                alt='Item Img'
            />
            <p className='rent-a-car-list-item__title'>
                {rentACar.Rentacarlanguages[0].Name}
            </p>
            <p className='rent-a-car-list-item__description'>
                {rentACar.Contact.replace('.', ' ')}
            </p>
            <p className='rent-a-car-list-item__description'>
                {rentACar.Rentacarlanguages[0].Location}
            </p>
        </div>
    );
}

export default RentACarListItem;
