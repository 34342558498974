import { IEventDTO } from './eventDTO';
import { IOccurrenceDTO } from './occurrenceDTO';

export enum NotificationEnum {
    RegisteredOccurrence = 'Registered Occurrence',
    ClosedOccurrence = 'Closed Occurrence',
    ReopenedOccurrence = 'Reopened Occurrence',
    NewWarning = 'New Warning',
}

export interface INotificationDTO {
    Id: number;
    AccountId: number;
    CreationDate: Date;
    Type: NotificationEnum;
    Archived: boolean;
    IsRead: boolean;
    IsSeen: boolean;
    Occurrence: IOccurrenceDTO;
    Event: IEventDTO;
}
