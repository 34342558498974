export const presidentMotion = {
    initial: { opacity: 0 },
    animate: {
        opacity: [0, 1],
        transition: {
            duration: 0.4,
            ease: 'easeIn',
            delay: 0.3,
        },
    },
};

export const presidentListItemMotion = {
    initial: { opacity: 0 },
    whileInView: 'animated',
    viewport: { once: true, amount: 0.3 },
    variants: {
        animated: {
            opacity: [0, 1],
            transition: {
                duration: 0.4,
                ease: 'easeIn',
            },
        },
    },
};
