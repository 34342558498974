/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { ReactComponent as GoBack } from 'assets/icons/arrow_icon.svg';
import { motion } from 'framer-motion';
import './styles.scss';
import { Dropdown, Input, TextArea, useForm } from 'linkwithus';
import SiteButton from 'components/SiteButton';
import { IDropdownItemDTO } from 'DTOModels/dropdownItemDTO';
import { axios } from 'startup';
import InformationalPopover from 'components/InformationalPopover';
import FileUploader from 'components/FileUploader';
import { registerOccurrencePageContentMotion } from './animation';
import { INewOccurrenceForm } from './interface';
import RegisterSuccess from './components/RegisterSuccess';

function RegisterOccurrencePage() {
    const { t } = useTranslation();
    const [occurrenceTypes, setOccurrenceTypes] = useState<IDropdownItemDTO[]>(
        [],
    );
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const navigate = useNavigate();

    const { register, registerSubmit, setForm } = useForm<INewOccurrenceForm>({
        url: '/occurrences',
        onSuccess: () => {
            setRegisterSuccess(true);
        },
        onError: (data: any, error: any) => {
            const errorCode = Number(error.errorCode) || 0;
            notification.error({
                message: t(`general.ERROR`),
                description: t(`errors.${errorCode}`),
            });
        },
        resolveData(rawForm) {
            const data = new FormData();
            Object.keys(rawForm).forEach(key => {
                if (key === 'photos') {
                    rawForm.photos.forEach((f: any) => {
                        data.append('photos', f as any);
                    });
                } else
                    data.append(
                        key,
                        rawForm[key as keyof INewOccurrenceForm] as any,
                    );
            });
            return data;
        },
    });

    useEffect(() => {
        const currentDate = new Date();
        axios
            .get(`/occurrences/types?date=${currentDate}`)
            .then((response: any) => {
                setOccurrenceTypes(response.data.Types);
            });
    }, []);

    const registerNewOccurrence = useCallback(() => {
        setRegisterSuccess(false);
        setForm({}, true);
    }, []);

    return (
        <div
            className={`register-occurrence-page ${
                registerSuccess ? 'center' : ''
            }`}
        >
            {registerSuccess && (
                <RegisterSuccess registerNew={registerNewOccurrence} />
            )}
            {!registerSuccess && (
                <motion.div
                    {...registerOccurrencePageContentMotion}
                    className='register-occurrence-page__content'
                >
                    <div className='register-occurrence-page__content__header'>
                        <button
                            type='button'
                            onClick={() => {
                                navigate('/');
                            }}
                            className='register-occurrence-page__content__header__button'
                        >
                            <GoBack />
                        </button>
                        <p className='register-occurrence-page__content__header__title'>
                            {t('occurrences.REGISTER_OCCURRENCE')}
                        </p>
                        <p className='register-occurrence-page__content__header__description'>
                            {t('occurrences.REGISTER_OCCURRENCE_DESCRIPTION')}
                        </p>
                    </div>
                    <div className='register-occurrence-page__content__form'>
                        <p className='register-occurrence-page__content__form__label'>
                            {t('form.ADDRESS')}
                        </p>
                        <Input
                            {...register('address', {
                                required: true,
                                resolveError: () => {
                                    return `${t('form.ADDRESS')} ${t(
                                        'form.REQUIRED_FIELD',
                                    )}`;
                                },
                            })}
                            placeholder={t('form.ADDRESS_PLACEHOLDER')}
                            className='register-occurrence-page__content__form__input-field'
                        />
                        <p className='register-occurrence-page__content__form__label'>
                            {t('form.TYPE')}
                        </p>
                        <Dropdown
                            items={occurrenceTypes}
                            {...register('type', {
                                required: true,
                                resolveError: () => {
                                    return `${t('form.TYPE')} ${t(
                                        'form.REQUIRED_FIELD',
                                    )}`;
                                },
                            })}
                            placeholder={t('form.TYPE_PLACEHOLDER')}
                            className='register-occurrence-page__content__form__input-field'
                        />
                        <p className='register-occurrence-page__content__form__label'>
                            {t('form.DESCRIPTION')}
                        </p>
                        <TextArea
                            {...register('description', {
                                required: true,
                                resolveError: () => {
                                    return `${t('form.DESCRIPTION')} ${t(
                                        'form.REQUIRED_FIELD',
                                    )}`;
                                },
                            })}
                            placeholder={t('form.DESCRIPTION_PLACEHOLDER')}
                            className='register-occurrence-page__content__form__input-field'
                            autoSize
                        />
                        <div className='register-occurrence-page__content__form__label'>
                            {t('form.OCCURRENCE_IMAGES')}
                            <InformationalPopover
                                title={t(
                                    'occurrences.IMAGE_RESTRICTIONS_TITLE',
                                )}
                                description={t(
                                    'occurrences.IMAGE_RESTRICTIONS',
                                )}
                            />
                        </div>
                        <FileUploader
                            {...register('photos', {
                                required: true,
                                resolveError: () => {
                                    return t('form.IMAGES_REQUIRED');
                                },
                            })}
                        />
                        <SiteButton
                            text={t('occurrences.SUBMIT')}
                            type='primary'
                            {...registerSubmit()}
                            className='full-width register-occurrence-page__content__form__submit-button'
                        />
                    </div>
                </motion.div>
            )}
        </div>
    );
}

export default RegisterOccurrencePage;
