import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import AddressIcon from 'assets/icons/address_icon.svg';
import ContactIcon from 'assets/icons/contact_icon.svg';
import EmailIcon from 'assets/icons/email_icon.svg';
import './styles.scss';
import { axios } from 'startup';
import config from 'config';
import { Spin, notification } from 'antd';
import PageBanner from 'components/PageBanner';
import { IContactDTO } from 'DTOModels/contactDTO';
import { SESSION_COOKIE_KEY, removeCookie } from 'services/cookies';
import { useAccount } from 'providers/AccountProvider';
import { useNavigate } from 'react-router-dom';
import { formatContact } from 'utils/format';
import { contactPageContentMotion } from './animation';

function ContactPage() {
    const { t } = useTranslation();
    const { setAccount } = useAccount();
    const navigate = useNavigate();
    const [info, setInfo] = useState<IContactDTO | undefined>();

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const fetch = () => {
        const currentDate = new Date();
        axios
            .get(`${config.API_URL}/contacts?date=${currentDate}`)
            .then(response => {
                setInfo(response.data.results);
            })
            .catch(() => {
                // force logout
                logout();
            });
    };

    useEffect(() => {
        fetch();
    }, []);

    return (
        <div className='contact-page'>
            <PageBanner
                title={t('menu.CONTACTS')}
                primaryPage={false}
                previous
            />
            <motion.div
                className='contact-page__content'
                {...contactPageContentMotion}
            >
                {!info ? (
                    <div className='contact-page__content__empty'>
                        <Spin />
                    </div>
                ) : (
                    <>
                        <p className='title'>{t('contacts.THANK_YOU')}</p>
                        <p>
                            <img src={AddressIcon} alt='Icon' />
                            {t('contacts.ADDRESS')}: {info.address}
                        </p>
                        <p>
                            <img src={ContactIcon} alt='Icon' />
                            {t('contacts.CONTACTS')}:{' '}
                            {formatContact(info.contactNumber)}
                        </p>
                        <p>
                            <img src={EmailIcon} alt='Icon' />
                            {t('contacts.EMAIL')}: {info.email}
                        </p>
                    </>
                )}
            </motion.div>
        </div>
    );
}

export default ContactPage;
