import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import PageBanner from 'components/PageBanner';
import RentACar from './components/RentACar';

function RentACarPage() {
    const { t } = useTranslation();

    return (
        <div className='rent-a-car-page'>
            <PageBanner
                title={t('menu.RENT_A_CAR')}
                primaryPage={false}
                previous
            />
            <RentACar />
        </div>
    );
}

export default RentACarPage;
