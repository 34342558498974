/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'providers/AccountProvider';
import { useNavigate } from 'react-router-dom';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import { Spin, notification } from 'antd';
import { motion } from 'framer-motion';
import RentACarIcon from 'assets/icons/accommodation_icon.svg';
import './styles.scss';
import { axios } from 'startup';
import config from 'config';
import { IRentACarDTO } from 'DTOModels/rentACarDTO';
import { rentACarMotion, rentACarListItemMotion } from './animation';
import RentACarListItem from './components/RentACarListItem';

const top = 10;

function RentACar() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true);
    const { setAccount } = useAccount();
    const [items, setItems] = useState<IRentACarDTO[]>([]);

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const fetchList = useCallback(() => {
        const currentDate = new Date();
        setHasMore(true);
        setLoading(true);
        axios
            .get(`${config.API_URL}/rentacar?top=${top}&date=${currentDate}`)
            .then(response => {
                setItems(
                    response.data.RentACars.map((item: IRentACarDTO) => ({
                        ...item,
                    })),
                );
                if (response.data.Total <= top) setHasMore(false);
                setLoading(false);
            })
            .catch(() => {
                // force logout
                logout();
            });
    }, []);

    const fetchMore = () => {
        const lastId = items[items.length - 1].Id;
        const currentDate = new Date();
        axios
            .get(
                `${config.API_URL}/rentacar?top=${top}&lastId=${lastId}&date=${currentDate}`,
            )
            .then(response => {
                setItems(m => [
                    ...m,
                    ...response.data.RentACars.filter(
                        (a: IRentACarDTO) => !items.some(e => e.Id === a.Id),
                    ),
                ]);
                if (response.data.Total <= top) setHasMore(false);
            })
            .catch(() => {
                // force logout
                logout();
            });
    };

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <div className='rent-a-cars'>
            <div className='rent-a-cars__content'>
                {loading && (
                    <div className='rent-a-cars__content__empty'>
                        <Spin className='' size='large' />
                    </div>
                )}
                {!loading && items.length === 0 && (
                    <motion.div
                        {...rentACarMotion}
                        className='rent-a-cars__content__empty'
                    >
                        <img src={RentACarIcon} alt='Rent a Car Icon' />
                        <p>
                            {t(
                                'accommodationAndGastronomy.NO_RENT_A_CAR_REGISTERED',
                            )}
                        </p>
                    </motion.div>
                )}
                {!loading && items.length > 0 && (
                    <motion.div
                        {...rentACarMotion}
                        className='rent-a-cars__content__list'
                    >
                        {items.map(
                            (rentACarItem: IRentACarDTO, index: number) => {
                                return (
                                    <motion.div
                                        {...rentACarListItemMotion}
                                        className='rent-a-cars__content__list__item'
                                        key={index}
                                    >
                                        <RentACarListItem
                                            rentACar={rentACarItem}
                                        />
                                    </motion.div>
                                );
                            },
                        )}
                    </motion.div>
                )}
            </div>
            {!loading && items.length > 0 && hasMore && (
                <button
                    type='button'
                    className='rent-a-cars__button'
                    onClick={() => {
                        fetchMore();
                    }}
                >
                    {t('general.SEE_MORE')}
                </button>
            )}
        </div>
    );
}

export default RentACar;
