import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'providers/AccountProvider';
import { getCookie, removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import LoadingPage from 'containers/LoadingPage';
import PageBackground from 'containers/PageBackground';
import { IRequiresAuthenticationProps } from './interface';

function RequiresAuthentication({ children }: IRequiresAuthenticationProps) {
    const { account, fetchAccount } = useAccount();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            if (account) return;

            if (!getCookie(SESSION_COOKIE_KEY)) {
                navigate('/login');
                return;
            }

            fetchAccount!((success: any) => {
                if (success) return;

                removeCookie(SESSION_COOKIE_KEY);
                navigate('/login');
            });
        }, 2000);
    }, []);

    if (!account)
        return (
            <PageBackground>
                <LoadingPage />
            </PageBackground>
        );

    return children;
}

export default RequiresAuthentication;
