/* eslint-disable react/no-array-index-key */
import React from 'react';
import './styles.scss';
import config from 'config';
import { IAssemblyPresidentListItem } from './interface';

function AssemblyPresidentListItem({
    assemblyPresident,
    className,
}: IAssemblyPresidentListItem) {
    return (
        <div className={`assembly-president-list-item ${className || ''}`}>
            <div className='assembly-president-list-item__img'>
                <img
                    src={`${config.API_URL}/assembly-presidents/images/${assemblyPresident.Id}`}
                    alt='Item Img'
                />
            </div>
            <p className='assembly-president-list-item__title'>
                {assemblyPresident.Name}
            </p>
            <div className='assembly-president-list-item__description'>
                {assemblyPresident.Info &&
                    assemblyPresident.Info.split('\r\n').map(
                        (paragraph: string, index: number) => {
                            return (
                                <p
                                    className='president-list-item__description__content'
                                    key={index}
                                >
                                    {paragraph}
                                </p>
                            );
                        },
                    )}
            </div>
        </div>
    );
}

export default AssemblyPresidentListItem;
