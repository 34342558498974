import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import PageBanner from 'components/PageBanner';
import President from './components/President';

function PresidentPage() {
    const { t } = useTranslation();

    return (
        <div className='president-page'>
            <PageBanner
                title={t('menu.PRESIDENT')}
                primaryPage={false}
                previous
            />
            <President />
        </div>
    );
}

export default PresidentPage;
