export const menuItem1Motion = {
    initial: { opacity: 0, x: -10 },
    animate: {
        opacity: [0, 1],
        x: [-10, 0],
        transition: {
            duration: 0.2,
            ease: 'easeIn',
        },
    },
};

export const menuItem2Motion = {
    initial: { opacity: 0, x: -10 },
    animate: {
        opacity: [0, 1],
        x: [-10, 0],
        transition: {
            duration: 0.2,
            ease: 'easeIn',
            delay: 0.05,
        },
    },
};

export const menuItem3Motion = {
    initial: { opacity: 0, x: -10 },
    animate: {
        opacity: [0, 1],
        x: [-10, 0],
        transition: {
            duration: 0.2,
            ease: 'easeIn',
            delay: 0.1,
        },
    },
};

export const menuItem4Motion = {
    initial: { opacity: 0, x: -10 },
    animate: {
        opacity: [0, 1],
        x: [-10, 0],
        transition: {
            duration: 0.2,
            ease: 'easeIn',
            delay: 0.15,
        },
    },
};

export const menuItem5Motion = {
    initial: { opacity: 0, x: -10 },
    animate: {
        opacity: [0, 1],
        x: [-10, 0],
        transition: {
            duration: 0.2,
            ease: 'easeIn',
            delay: 0.2,
        },
    },
};
