import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import PageBanner from 'components/PageBanner';
import PlacesToVisit from './components/PlacesToVisit';

function PlacesToVisitPage() {
    const { t } = useTranslation();

    return (
        <div className='places-to-visit-page'>
            <PageBanner
                title={t('menu.LANDMARKS')}
                primaryPage={false}
                previous
            />
            <PlacesToVisit />
        </div>
    );
}

export default PlacesToVisitPage;
