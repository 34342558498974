/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SuccessImage from 'assets/images/forms/success.png';
import { motion } from 'framer-motion';
import SiteButton from 'components/SiteButton';
import './styles.scss';
import { registerSuccessContentMotion } from './animation';
import { INewOccurrenceForm } from './interface';

function RegisterSuccess({ registerNew }: INewOccurrenceForm) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <motion.div
            {...registerSuccessContentMotion}
            className='register-success'
        >
            <img src={SuccessImage} alt='Success Img' />
            <p className='register-success__title'>
                {t('occurrences.REGISTER_SUCCESS')}
            </p>
            <p className='register-success__description'>
                {t('occurrences.REGISTER_SUCCESS_THANK_YOU')}
            </p>
            <SiteButton
                text={t('general.OCCURRENCES_LIST')}
                type='primary'
                className='full-width grey'
                onClick={() => {
                    navigate('/');
                }}
            />
            <button
                type='button'
                onClick={registerNew}
                className='register-success__new-occurrence-button'
            >
                {t('occurrences.REGISTER_NEW_OCCURRENCE')}
            </button>
        </motion.div>
    );
}

export default RegisterSuccess;
