/* eslint-disable react/no-array-index-key */
import React from 'react';
import './styles.scss';
import config from 'config';
import { IPlacesToVisitListItem } from './interface';

function PlacesToVisitListItem({ place, className }: IPlacesToVisitListItem) {
    return (
        <div className={`places-to-visit-list-item ${className || ''}`}>
            <p className='places-to-visit-list-item__title'>
                {place.Placelanguages[0].Name}
            </p>
            <div className='places-to-visit-list-item__description'>
                {place.Placelanguages[0].Description &&
                    place.Placelanguages[0].Description.split('\r\n').map(
                        (paragraph: string, index: number) => {
                            return <p key={index}>{paragraph}</p>;
                        },
                    )}
            </div>
            <img
                src={`${config.API_URL}/places/images/${place.Id}`}
                alt='Item Img'
            />
        </div>
    );
}

export default PlacesToVisitListItem;
