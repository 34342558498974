const msInMinute = 60000;
const msInHour = 3600000;
const msInDay = 86400000;
const msInMonth = 2629746000;
const msInYear = 365 * 86400000;

export function timeSince(date: Date) {
    const differenceInMs = Math.round(
        Math.abs(new Date().getTime() - new Date(date).getTime()),
    );

    if (
        differenceInMs < msInMinute ||
        (differenceInMs > msInMinute && differenceInMs < msInHour)
    ) {
        const minutesSince = differenceInMs / msInMinute;
        return Math.round(minutesSince) > 1
            ? { time: Math.round(minutesSince), type: 'MINUTES' }
            : { time: 1, type: 'MINUTE' };
    }

    if (differenceInMs > msInHour && differenceInMs < msInDay) {
        const hoursSince = differenceInMs / msInHour;
        return Math.round(hoursSince) > 1
            ? { time: Math.round(hoursSince), type: 'HOURS' }
            : { time: 1, type: 'HOUR' };
    }

    if (differenceInMs > msInDay && differenceInMs < msInMonth) {
        const daysSince = differenceInMs / msInDay;
        return Math.round(daysSince) > 1
            ? { time: Math.round(daysSince), type: 'DAYS' }
            : { time: 1, type: 'DAY' };
    }

    if (differenceInMs > msInMonth && differenceInMs < msInYear) {
        const monthsSince = differenceInMs / msInMonth;
        return Math.round(monthsSince) > 1
            ? { time: Math.round(monthsSince), type: 'MONTHS' }
            : { time: 1, type: 'MONTH' };
    }

    const yearsSince = differenceInMs / msInYear;
    return Math.round(yearsSince) > 1
        ? { time: Math.round(yearsSince), type: 'YEARS' }
        : { time: 1, type: 'YEAR' };
}
