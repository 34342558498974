import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import PageBanner from 'components/PageBanner';
import TravelAgencies from './components/TravelAgencies';

function TravelAgenciesPage() {
    const { t } = useTranslation();

    return (
        <div className='travel-agencies-page'>
            <PageBanner
                title={t('menu.TRAVEL_AGENCIES')}
                primaryPage={false}
                previous
            />
            <TravelAgencies />
        </div>
    );
}

export default TravelAgenciesPage;
