import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BackgroundImage from 'assets/images/backgrounds/login_background.png';
import './styles.scss';
import { motion } from 'framer-motion';
import SiteButton from 'components/SiteButton';
import FBLogin from 'components/FacebookLogin';
import { loginFooterMotion, loginFormMotion } from './animation';
import LoginForm from './components/LoginForm';

function LoginPage() {
    const { t } = useTranslation();
    const [hasAccountToLogin, setHasAccountToLogin] = useState(false);

    return (
        <div className='login-page'>
            {!hasAccountToLogin && (
                <>
                    <div className='login-page__spacer' />
                    <motion.div
                        className='login-page__header'
                        {...loginFormMotion}
                    >
                        <p className='login-page__header__title'>
                            {t('general.COUNTY')}
                        </p>
                        <p className='login-page__header__text'>
                            {t('general.CRIOLO')}
                        </p>
                    </motion.div>
                    <motion.div
                        className='login-page__form'
                        {...loginFormMotion}
                    >
                        <SiteButton
                            text={t('general.LOGIN')}
                            type='primary'
                            onClick={() => {
                                setHasAccountToLogin(true);
                            }}
                            className='login-page__form__login-button uppercase full-width'
                        />
                        <FBLogin />
                    </motion.div>
                    <motion.div
                        className='login-page__footer-message'
                        {...loginFooterMotion}
                    >
                        <p>{t('signup.ACCOUNT_OWNER_QUESTION')}</p>
                        <Link
                            to='/sign-up'
                            className='login-page__footer-message__link'
                        >
                            <span>{t('signup.CREATE_ACCOUNT')}</span>
                        </Link>
                    </motion.div>
                    <img
                        className='login-page__background'
                        src={BackgroundImage}
                        alt='Background Img'
                    />
                </>
            )}
            {hasAccountToLogin && <LoginForm />}
        </div>
    );
}

export default LoginPage;
