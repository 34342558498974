import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import NewNotificationIcon from 'assets/icons/unread_notification_icon.svg';
import OldNotificationIcon from 'assets/icons/read_notification_icon.svg';
import './styles.scss';
import moment from 'moment';
import { NotificationEnum } from 'DTOModels/notificationDTO';
import { INotificationListItem } from './interface';

function NotificationListItem({ notification }: INotificationListItem) {
    const { t } = useTranslation();

    return (
        <div className='notification-list-item'>
            <Link
                to={`/notification/${notification.Id}`}
                className='notification-list-item__content'
            >
                <div className='notification-list-item__content__left-column'>
                    <div
                        className={`notification-list-item__content__left-column__circle ${
                            notification.IsRead ? '' : 'unread'
                        }`}
                    >
                        {notification.IsRead ? (
                            <img src={OldNotificationIcon} alt='Icon' />
                        ) : (
                            <img src={NewNotificationIcon} alt='Icon' />
                        )}
                    </div>
                    <div className='notification-list-item__content__left-column__text'>
                        <p className='notification-list-item__content__left-column__text__title'>
                            {notification.Type ===
                                NotificationEnum.RegisteredOccurrence &&
                                t('notifications.OCCURRENCE_REGISTERED')}
                            {notification.Type ===
                                NotificationEnum.ClosedOccurrence &&
                                t('notifications.OCCURRENCE_CONCLUDED')}
                            {notification.Type ===
                                NotificationEnum.ReopenedOccurrence &&
                                t('notifications.OCCURRENCE_REOPENED')}
                            {notification.Type ===
                                NotificationEnum.NewWarning &&
                                t('notifications.NEW_WARNING')}
                        </p>
                        <p className='notification-list-item__content__left-column__text__description'>
                            {notification.Type ===
                                NotificationEnum.RegisteredOccurrence &&
                                t('notifications.OCCURRENCE_REGISTERED_BODY')}
                            {notification.Type ===
                                NotificationEnum.ClosedOccurrence &&
                                `${t(
                                    'notifications.OCCURRENCE_CONCLUDED_BODY_1',
                                )} ${moment(
                                    notification.Occurrence.CreationDate,
                                ).format('D/MM/YYYY')} ${t(
                                    'notifications.OCCURRENCE_CONCLUDED_BODY_2',
                                )} ${moment(notification.CreationDate).format(
                                    'D/MM/YYYY',
                                )}.`}
                            {notification.Type ===
                                NotificationEnum.ReopenedOccurrence &&
                                `${t(
                                    'notifications.OCCURRENCE_CONCLUDED_BODY_1',
                                )} ${moment(
                                    notification.Occurrence.CreationDate,
                                ).format('D/MM/YYYY')} ${t(
                                    'notifications.OCCURRENCE_REOPENED_BODY_2',
                                )} ${moment(notification.CreationDate).format(
                                    'D/MM/YYYY',
                                )}.  ${t('notifications.APOLOGIES')}`}
                            {notification.Type ===
                                NotificationEnum.NewWarning &&
                                notification.Event.Eventlanguages[0].Name}
                        </p>
                    </div>
                </div>
                <div className='notification-list-item__content__right-column'>
                    {moment(notification.CreationDate).format('D/MM/YYYY')}
                </div>
            </Link>
        </div>
    );
}

export default NotificationListItem;
