import React, { useContext, useMemo, useState } from 'react';
import { axios } from '../startup';

interface INewNotificationsProviderProps {
    children: JSX.Element;
}

interface INewNotificationsContext {
    newNotifications?: boolean;
    setNewNotifications?: React.Dispatch<
        React.SetStateAction<boolean | undefined>
    >;
    setFetchTry?: React.Dispatch<React.SetStateAction<boolean>>;
    fetchNewNotifications?: (callback: (success: boolean) => void) => void;
    fetchTry?: boolean;
}

const NewNotificationsContext = React.createContext<INewNotificationsContext>(
    {},
);

export const useNewNotifications = () => useContext(NewNotificationsContext);

function NewNotificationsProvider({
    children,
}: INewNotificationsProviderProps) {
    const [newNotifications, setNewNotifications] = useState<boolean>();
    const [fetchTry, setFetchTry] = useState<boolean>(false);
    const value = useMemo<INewNotificationsContext>(
        () => ({
            newNotifications,
            setNewNotifications,
            setFetchTry,
            fetchNewNotifications: callback => {
                const currentDate = new Date();
                axios
                    .get(`/notifications/new?date=${currentDate}`)
                    .then((res: any) => {
                        setNewNotifications(res.data.HasNew);
                        callback(true);
                    })
                    .catch(() => callback(false))
                    .finally(() => setFetchTry(true));
            },
            fetchTry,
        }),
        [newNotifications, fetchTry],
    );

    return (
        <NewNotificationsContext.Provider value={value}>
            {children}
        </NewNotificationsContext.Provider>
    );
}

export default NewNotificationsProvider;
