import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './styles.scss';
import { notification } from 'antd';
import PageBanner from 'components/PageBanner';
import { TextArea, useForm } from 'linkwithus';
import SiteButton from 'components/SiteButton';
import { suggestionsPageContentMotion } from './animation';
import { ISuggestionForm } from './interface';

function SuggestionsPage() {
    const { t } = useTranslation();
    const { register, registerSubmit, resetForm } = useForm<ISuggestionForm>({
        url: '/suggestions',
        onSuccess: () => {
            notification.success({
                message: t('general.SUCCESS'),
                description: t('suggestions.SUBMIT_SUCCESS'),
            });
            resetForm();
        },
        onError: (data, error: any) => {
            const errorCode = Number(error.errorCode) || 0;
            notification.error({
                message: t(`general.ERROR`),
                description: t(`errors.${errorCode}`),
            });
            resetForm();
        },
    });

    return (
        <div className='suggestions-page'>
            <PageBanner
                title={t('menu.SUGGESTIONS')}
                primaryPage={false}
                previous
            />
            <motion.div
                className='suggestions-page__content'
                {...suggestionsPageContentMotion}
            >
                <div className='suggestions-page__content__form'>
                    <p className='title'>{t('suggestions.INTRO')}</p>
                    <TextArea
                        {...register('text', {
                            required: true,
                            resolveError: () => {
                                return t('form.SUGGESTION_EMPTY');
                            },
                        })}
                        placeholder={t('suggestions.PLACEHOLDER')}
                        autoSize
                    />
                </div>
                <SiteButton
                    text={t('suggestions.SUBMIT')}
                    type='primary'
                    {...registerSubmit()}
                    className='full-width suggestions-page__content__button'
                />
            </motion.div>
        </div>
    );
}

export default SuggestionsPage;
