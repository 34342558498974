import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './styles.scss';
import Marker from 'assets/icons/list_marker_icon.svg';
import PageBanner from 'components/PageBanner';
import { aboutPageContentMotion } from './animation';

function AboutPage() {
    const { t } = useTranslation();
    return (
        <div className='about-page'>
            <PageBanner title={t('menu.ABOUT')} primaryPage={false} previous />
            <motion.div
                className='about-page__content'
                {...aboutPageContentMotion}
            >
                <p className='title'>{t('about.MISSION')}</p>
                <p className='text'>{t('about.MISSION_TEXT')}</p>
                <p className='title margin'>{t('about.VISION')}</p>
                <p className='text'>{t('about.VISION_TEXT')}</p>
                <p className='title margin'>{t('about.VALUES')}</p>
                <div>
                    <img src={Marker} alt='Marker' />
                    <p>{t('about.EFFICIENCY')}</p>
                </div>
                <div>
                    <img src={Marker} alt='Marker' />
                    <p>{t('about.EQUALITY')}</p>
                </div>
                <div>
                    <img src={Marker} alt='Marker' />
                    <p>{t('about.RESPONSABILITY')}</p>
                </div>
                <div>
                    <img src={Marker} alt='Marker' />
                    <p>{t('about.TRANSPARENCY')}</p>
                </div>
                <div>
                    <img src={Marker} alt='Marker' />
                    <p>{t('about.PARTICIPATION')}</p>
                </div>
            </motion.div>
        </div>
    );
}

export default AboutPage;
