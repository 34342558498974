import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { ReactComponent as GoBack } from 'assets/icons/arrow_icon.svg';
import { ReactComponent as ForgotPasswordIcon } from 'assets/icons/email_sent_icon.svg';
import { motion } from 'framer-motion';
import SiteButton from 'components/SiteButton';
import { forgotPasswordPageContentMotion } from 'containers/ForgotPasswordPage/animation';
import { useForm } from 'linkwithus';
import { IForgotPasswordStepProps } from 'containers/ForgotPasswordPage/interface';
import PinInput from 'components/PinInput';
import { IForgotPasswordStep2FormProps } from './interface';

function Step2({ nextStep, token, setCode, email }: IForgotPasswordStepProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const INITIAL_FORM = {
        code: '    ',
        token,
    };

    const { registerSubmit, form, updateForm } =
        useForm<IForgotPasswordStep2FormProps>({
            url: 'accounts/forgot-password/verify-code',
            initialForm: INITIAL_FORM,
            onSuccess: () => {
                setCode(form.code);
                nextStep();
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
            },
        });

    return (
        <motion.div
            {...forgotPasswordPageContentMotion}
            className='forgot-password-page__content'
        >
            <div className='forgot-password-page__content__header'>
                <button
                    className='forgot-password-page__content__header__button'
                    type='button'
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <GoBack className='forgot-password-page__content__header__button__go-back-icon' />
                </button>
                <p className='forgot-password-page__content__header__text'>
                    {t('forgotPassword.VERIFY_EMAIL')}
                </p>
            </div>
            <div className='forgot-password-page__content__body'>
                <ForgotPasswordIcon />
                <p className='forgot-password-page__content__body__confirm'>
                    {t('forgotPassword.INSERT_CODE')} {email}
                </p>
                <PinInput
                    value={form.code}
                    onChange={value => {
                        updateForm('code', value);
                    }}
                />
            </div>
            <div className='forgot-password-page__content__footer'>
                <SiteButton
                    text={t('general.SEND')}
                    type='primary'
                    className='full-width'
                    {...registerSubmit()}
                />
            </div>
        </motion.div>
    );
}

export default Step2;
