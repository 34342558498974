import React, { useEffect, useState } from 'react';
import Logo from 'assets/logos/municipio_logo.png';
import MenuIcon from 'assets/icons/menu_icon.svg';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import HamburgerMenu from './components/Hamburger';

function Menu() {
    const { t } = useTranslation();
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        setMenuOpen(false);
    }, [location.pathname]);

    return (
        <div className='menu'>
            <div className='menu__content'>
                <Link to='/' className='menu__content__logo'>
                    <img src={Logo} alt='Logo' />
                    <div className='menu__content__logo__county'>
                        <p className='uppercase'>{t('general.COUNTY_OF')}</p>
                        <p className='bold uppercase'>{t('general.COUNTY')}</p>
                        <p>{t('general.ISLAND')}</p>
                    </div>
                </Link>
                <button
                    type='button'
                    className='menu__content__hamburger-icon'
                    onClick={() => {
                        setMenuOpen(!menuOpen);
                    }}
                >
                    <img src={MenuIcon} alt='Menu' />
                </button>
                <HamburgerMenu className={`${menuOpen ? 'active' : ''}`} />
            </div>
        </div>
    );
}

export default Menu;
