import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { ReactComponent as GoBack } from 'assets/icons/arrow_icon.svg';
import { ReactComponent as ChangePasswordIcon } from 'assets/icons/change_password_icon.svg';
import { motion } from 'framer-motion';
import SiteButton from 'components/SiteButton';
import { forgotPasswordPageContentMotion } from 'containers/ForgotPasswordPage/animation';
import { PasswordInput, useForm } from 'linkwithus';
import { IForgotPasswordStepProps } from 'containers/ForgotPasswordPage/interface';
import { passwordRegex } from 'utils/regex';
import { IForgotPasswordStep3FormProps } from './interface';

function Step3({ nextStep, token, code }: IForgotPasswordStepProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const INITIAL_FORM = {
        code,
        token,
    };

    const { register, registerSubmit, form } =
        useForm<IForgotPasswordStep3FormProps>({
            url: 'accounts/forgot-password/reset-password',
            initialForm: INITIAL_FORM,
            onSuccess: () => {
                nextStep();
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
            },
        });

    const { password } = form;

    return (
        <motion.div
            {...forgotPasswordPageContentMotion}
            className='forgot-password-page__content'
        >
            <div className='forgot-password-page__content__header'>
                <button
                    className='forgot-password-page__content__header__button'
                    type='button'
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <GoBack className='forgot-password-page__content__header__button__go-back-icon' />
                </button>
                <p className='forgot-password-page__content__header__text'>
                    {t('forgotPassword.CREATE_NEW_PASSWORD')}
                </p>
            </div>
            <div className='forgot-password-page__content__body'>
                <ChangePasswordIcon />
                <p className='forgot-password-page__content__body__confirm'>
                    {t('forgotPassword.INSERT_NEW_PASSWORD')}
                </p>
                <p className='forgot-password-page__content__body__label'>
                    {t('form.PASSWORD')}
                </p>
                <PasswordInput
                    {...register('password', {
                        required: true,
                        regex: passwordRegex,
                        resolveError: (val, error) => {
                            if (error.type === 'regex') {
                                return `${t('form.PASSWORD')} ${t(
                                    'form.PASSWORD_FORMAT',
                                )}`;
                            }
                            return `${t('form.PASSWORD')} ${t(
                                'form.REQUIRED_FIELD',
                            )}`;
                        },
                    })}
                    className='forgot-password-page__content__body__input-field margin'
                />
                <p className='forgot-password-page__content__body__label'>
                    {t('form.CONFIRM_PASSWORD')}
                </p>
                <PasswordInput
                    {...register('confirmPassword', {
                        required: true,
                        isValid: v => v === password,
                        resolveError: (v, err) =>
                            err.type === 'custom-validation'
                                ? `${t('form.PASSWORD')} ${t(
                                      'form.REQUIRED_FIELD',
                                  )}`
                                : t('form.NON_MATCHING_PASSWORDS'),
                    })}
                    className='forgot-password-page__content__body__last-input-field'
                />
            </div>
            <div className='forgot-password-page__content__footer'>
                <SiteButton
                    text={t('general.SAVE')}
                    type='primary'
                    className='full-width'
                    {...registerSubmit()}
                />
            </div>
        </motion.div>
    );
}

export default Step3;
