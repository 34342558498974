import { useEffect } from 'react';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import { useAccount } from 'providers/AccountProvider';
import { RequireAuthProps } from './interface';

function OptionalAuthentication({ children }: RequireAuthProps) {
    const { fetchTry, fetchAccount } = useAccount();

    useEffect(() => {
        if (fetchTry) {
            return;
        }

        fetchAccount!((success: any) => {
            if (success) return;
            removeCookie(SESSION_COOKIE_KEY);
        });
    }, []);

    if (!fetchTry) return null;

    return children;
}

export default OptionalAuthentication;
