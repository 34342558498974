import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import PageBanner from 'components/PageBanner';
import Accommodations from './components/Accommodations';

function AccommodationsPage() {
    const { t } = useTranslation();

    return (
        <div className='accommodations-page'>
            <PageBanner
                title={t('menu.ACCOMMODATION')}
                primaryPage={false}
                previous
            />
            <Accommodations />
        </div>
    );
}

export default AccommodationsPage;
