/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import config from 'config';
import './styles.scss';
import ImageModal from 'containers/OccurrencePage/components/ImageModal';
import {
    CAROUSEL_ITEM_GAP,
    CAROUSEL_ITEM_HEIGHT,
    CAROUSEL_ITEM_WIDTH,
    ISwipeableCarouselProps,
} from './interface';

function SwipeableCarousel({
    elements,
    admin = false,
}: ISwipeableCarouselProps) {
    // carousel ref
    const carouselRef = useRef<HTMLDivElement>(null);
    // carousel visible width on screen
    const [width, setWidth] = useState(0);
    // carousel currentState
    const [carouselState, setCarouselState] = useState(0);
    // MaxCarousel currentState
    const [maxCarouselState, setMaxCarouselState] = useState(0);
    // ImageModalVisibility
    const [modalVisibility, setModalVisibility] = useState(false);
    // CurrentImage
    const [currentImageUrl, setCurrentImageUrl] = useState('');

    const handlers2 = useSwipeable({
        onSwipedLeft: () => {
            if (-carouselState < maxCarouselState) {
                setCarouselState(carouselState - 1);
            }
        },
        onSwipedRight: () => {
            if (-carouselState > 0) {
                setCarouselState(carouselState + 1);
            }
        },
        trackMouse: true,
    });

    const openImage = (imageUrl: string) => {
        setCurrentImageUrl(imageUrl);
        setModalVisibility(true);
    };

    useEffect(() => {
        if (carouselRef.current) {
            setWidth(carouselRef.current.offsetWidth);
        }
    }, [carouselRef.current?.offsetWidth]);

    useEffect(() => {
        if (elements && elements.length > 0 && width) {
            setMaxCarouselState(
                Math.ceil(
                    elements.length -
                        // eslint-disable-next-line prettier/prettier
                        (width / (CAROUSEL_ITEM_WIDTH + CAROUSEL_ITEM_GAP)),
                ),
            );
        }
    }, [elements, width]);

    return (
        <div ref={carouselRef} className='swipeable-carousel'>
            <ImageModal
                modalVisibility={modalVisibility}
                close={() => setModalVisibility(false)}
                imageUrl={currentImageUrl}
                containerWidth={width}
            />
            <div className='swipeable-carousel__container' {...handlers2}>
                <div
                    className='swipeable-carousel__container__translater'
                    style={{
                        gap: `${CAROUSEL_ITEM_GAP}px`,
                        transform: `translateX(${
                            carouselState *
                            (CAROUSEL_ITEM_WIDTH + CAROUSEL_ITEM_GAP)
                        }px)`,
                    }}
                >
                    {elements.map((image, index) => (
                        <button
                            type='button'
                            key={index}
                            onClick={() => {
                                openImage(
                                    `${config.API_URL}/occurrences/${
                                        admin ? 'conclusion/image' : 'images'
                                    }/${image.Id}?occurrence=${
                                        image.OccurrenceId
                                    })`,
                                );
                            }}
                            className='swipeable-carousel__container__translater__container'
                        >
                            <div
                                className='swipeable-carousel__container__translater__container__element'
                                style={{
                                    width: `${CAROUSEL_ITEM_WIDTH}px`,
                                    height: `${CAROUSEL_ITEM_HEIGHT}px`,
                                    backgroundImage: `url(${
                                        config.API_URL
                                    }/occurrences/${
                                        admin ? 'conclusion/image' : 'images'
                                    }/${image.Id}?occurrence=${
                                        image.OccurrenceId
                                    })`,
                                }}
                            />
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SwipeableCarousel;
