import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './styles.scss';
import { notification } from 'antd';
import PageBanner from 'components/PageBanner';
import FacebookIcon from 'assets/icons/facebook_icon.png';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Input, PasswordInput, PhoneInput, useForm } from 'linkwithus';
import SiteButton from 'components/SiteButton';
import { passwordRegex, phoneRegex } from 'utils/regex';
import { useAccount } from 'providers/AccountProvider';
import { settingsPageContentMotion } from './animation';
import { ISettingsForm } from './interface';

function SettingsPage() {
    const { t } = useTranslation();
    const { account, setAccount } = useAccount();
    const { register, registerSubmit, updateForm, submit, form, setForm } =
        useForm<ISettingsForm>({
            url: '/accounts/settings',
            onSuccess: (res: any) => {
                if (res.account) {
                    setAccount!(res.account);
                    notification.success({
                        message: t('general.SUCCESS'),
                        description: t('settings.SUCCESS'),
                    });
                }
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
            },
        });

    useEffect(() => {
        if (account) {
            const initialForm: ISettingsForm = {
                name: account.name,
                contact: `${account.contactNumber}`,
                viaFacebook: false,
            };
            setForm(initialForm);
        }
    }, [account]);

    const responseFacebook = (response: any) => {
        if (response.accessToken && response.email && response.name) {
            if (response.email !== account?.email) {
                notification.error({
                    message: t('general.ERROR'),
                    description: t('settings.EMAILS_DONT_MATCH'),
                });
            } else {
                updateForm('viaFacebook', true);
                if (
                    form.name &&
                    form.contact &&
                    phoneRegex.test(form.contact)
                ) {
                    submit();
                } else {
                    notification.error({
                        message: t('general.ERROR'),
                        description: t('settings.MISSING_OR_INCORRECT'),
                    });
                }
            }
        } else {
            notification.error({
                message: t('general.ERROR'),
                description: t('facebookLogin.ERROR'),
            });
        }
    };

    const { password } = form;

    return (
        <div className='settings-page'>
            <PageBanner
                title={t('general.SETTINGS')}
                primaryPage={false}
                previous
            />
            <motion.div
                className='settings-page__content'
                {...settingsPageContentMotion}
            >
                <div className='settings-page__content__form'>
                    <p className='settings-page__content__form__title'>
                        {t('settings.USER_DATA_CHANGE')}
                    </p>
                    <p className='settings-page__content__form__label'>
                        {t('form.NAME')}
                    </p>
                    <Input
                        {...register('name', {
                            required: true,
                            resolveError: () =>
                                `${t('form.NAME')} ${t('form.REQUIRED_FIELD')}`,
                        })}
                        className='settings-page__content__form__input-field'
                    />
                    <p className='settings-page__content__form__label'>
                        {t('form.EMAIL')}
                    </p>
                    <Input
                        value={account?.email}
                        onChange={() => {
                            notification.error({
                                message: t(`general.ERROR`),
                                description: t(
                                    'settings.EMAIL_CANNOT_BE_CHANGED',
                                ),
                            });
                        }}
                        disabled
                        className='settings-page__content__form__input-field'
                    />
                    <p className='settings-page__content__form__label'>
                        {t('form.CONTACT')}
                    </p>
                    <PhoneInput
                        {...register('contact', {
                            required: true,
                            regex: phoneRegex,
                            resolveError: (val, error) => {
                                if (error.type === 'regex') {
                                    return `${t('general.THE')} ${t(
                                        'form.CONTACT',
                                    )} ${t('form.FORMAT_INVALID')}`;
                                }
                                return `${t('form.CONTACT')} ${t(
                                    'form.REQUIRED_FIELD',
                                )}`;
                            },
                        })}
                        className='settings-page__content__form__input-field'
                    />
                    {!account?.createdViaFacebook && (
                        <>
                            <p className='settings-page__content__form__label'>
                                {t('form.CURRENT_PASSWORD')}
                            </p>
                            <PasswordInput
                                {...register('oldPassword', {
                                    required: true,
                                    resolveError: () => {
                                        return `${t('form.PASSWORD')} ${t(
                                            'form.REQUIRED_FIELD',
                                        )}`;
                                    },
                                })}
                                className='settings-page__content__form__input-field'
                            />
                            <p className='settings-page__content__form__label'>
                                {t('form.NEW_PASSWORD')}
                            </p>
                            <PasswordInput
                                {...register('password', {
                                    required: false,
                                    regex: passwordRegex,
                                    resolveError: (val, error) => {
                                        if (error.type === 'regex') {
                                            return `${t('form.PASSWORD')} ${t(
                                                'form.PASSWORD_FORMAT',
                                            )}`;
                                        }
                                        return `${t('form.PASSWORD')} ${t(
                                            'form.REQUIRED_FIELD',
                                        )}`;
                                    },
                                })}
                                className='settings-page__content__form__input-field'
                            />
                            <p className='settings-page__content__form__label'>
                                {t('form.CONFIRM_PASSWORD')}
                            </p>
                            <PasswordInput
                                {...register('confirmPassword', {
                                    required: !!form.password,
                                    isValid: v => v === password,
                                    resolveError: (v, err) =>
                                        err.type === 'custom-validation'
                                            ? `${t('form.PASSWORD')} ${t(
                                                  'form.REQUIRED_FIELD',
                                              )}`
                                            : t('form.NON_MATCHING_PASSWORDS'),
                                })}
                                className='settings-page__content__form__last-input-field'
                            />
                        </>
                    )}
                </div>
                {!account?.createdViaFacebook && (
                    <SiteButton
                        text={t('form.SAVE_CHANGES')}
                        type='primary'
                        {...registerSubmit()}
                        className='full-width settings-page__content__button'
                    />
                )}
                {account?.createdViaFacebook && (
                    <FacebookLogin
                        appId='685649796612615'
                        autoLoad={false}
                        fields='name,email,picture'
                        scope='public_profile,email,user_friends'
                        callback={responseFacebook}
                        icon='fa-facebook'
                        render={renderProps => (
                            <SiteButton
                                text={t('form.SAVE_CHANGES')}
                                onClick={renderProps.onClick}
                                type='primary'
                                icon={
                                    <img
                                        src={FacebookIcon}
                                        alt='Document Icon'
                                    />
                                }
                                className='blue full-width settings-page__content__button'
                            />
                        )}
                    />
                )}
            </motion.div>
        </div>
    );
}

export default SettingsPage;
