import React from 'react';
// import BackgroundImage from 'assets/images/backgrounds/page_sample_background.jpg';
import { PageBackgroundProps } from './interface';
import './styles.scss';

function PageBackground({ children }: PageBackgroundProps) {
    return (
        <div className='background'>
            <div className='background__page-container'>{children}</div>
            {/* 
            <img src={BackgroundImage} alt='Background Img' /> */}
        </div>
    );
}

export default PageBackground;
