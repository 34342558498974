import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './styles.scss';
import PageBanner from 'components/PageBanner';
import { privacyPolicySectionContentMotion } from './animation';

function PrivacyPolicyPage() {
    const { t } = useTranslation();
    return (
        <div className='privacy-policy-page'>
            <PageBanner
                title={t('menu.PRIVACY_POLICY')}
                primaryPage={false}
                previous
            />
            <div className='privacy-policy-page__content'>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='text'
                >
                    {t('privacyPolicy.HEADER')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='title margin'
                >
                    {t('privacyPolicy.INPERSONAL_DATA')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='text'
                >
                    {t('privacyPolicy.INPERSONAL_DATA_BODY')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='title margin'
                >
                    {t('privacyPolicy.PERSONAL_DATA')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='text'
                >
                    {t('privacyPolicy.PERSONAL_DATA_BODY')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='title margin'
                >
                    {t('privacyPolicy.TECHNOLOGY_AND_SAFETY')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='text'
                >
                    {t('privacyPolicy.TECHNOLOGY_AND_SAFETY_BODY')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='title margin'
                >
                    {t('privacyPolicy.STORAGE_DATA_CORRECTION')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='text'
                >
                    {t('privacyPolicy.STORAGE_DATA_CORRECTION_BODY')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='title margin'
                >
                    {t('privacyPolicy.CHANGES')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='text'
                >
                    {t('privacyPolicy.CHANGES_BODY')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='title margin'
                >
                    {t('privacyPolicy.DATA_PROTECTION')}
                </motion.p>
                <motion.p
                    {...privacyPolicySectionContentMotion}
                    className='text'
                >
                    {t('privacyPolicy.DATA_PROTECTION_BODY')}
                </motion.p>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;
