/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { Upload, notification } from 'antd';
import classNames from 'classnames';
import UploaderIcon from 'assets/icons/file_uploader_icon.svg';
import UploadCrossIcon from 'assets/icons/upload_cross_icon.svg';
import { useTranslation } from 'react-i18next';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { InputErrorWrapper } from 'linkwithus';
import { filesType, IFileUploaderFile, IFileUploaderProps } from './interface';
import './styles.scss';

const { Dragger } = Upload;

// 3 MB
const MAX_FILE_SIZE = 3;
// 5 files
const MAX_NUMBER_OF_FILES = 5;

function validNumberOfDocuments(x: number) {
    return x < MAX_NUMBER_OF_FILES;
}

function validDocumentSize(x: number) {
    return x <= MAX_FILE_SIZE * 1000000;
}

function niceBytes(x: number) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0;
    let n = x;
    // eslint-disable-next-line no-plusplus
    while (n >= 1024 && ++l) {
        n /= 1024;
    }
    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
}

function FileUploader({
    multiple = true,
    accept,
    disabled,
    onChange,
    className,
    error,
    value,
    disableFileRemove,
    ...rest
}: IFileUploaderProps) {
    const fileListRef = useRef<UploadFile[]>([]);
    const { t } = useTranslation();

    const onFileChanges = ({ fileList }: any) => {
        if (multiple) {
            let newFiles: (RcFile | IFileUploaderFile)[] = [];

            if (Array.isArray(value)) newFiles = value;
            else if (value) newFiles = [value];

            fileList.forEach((f: any) => {
                if (
                    !newFiles.some((e: any) => e.uid === f.originFileObj!.uid)
                ) {
                    let errorType = '';
                    const type = f.originFileObj!.type as string;
                    const regex = /image.(jpg|png|jpeg)$/;
                    // check size, type, number of documents
                    const validSize = validDocumentSize(f.originFileObj!.size);
                    const validType = regex.test(type);
                    const validNumber = validNumberOfDocuments(newFiles.length);
                    if (!validSize) errorType = 'IMAGE_SIZE_INVALID';
                    if (!validType) errorType = 'IMAGE_FORMAT_INVALID';
                    if (!validNumber) errorType = 'MAX_IMAGE_NUMBER_REACHED';
                    if (!validType || !validSize || !validNumber) {
                        notification.error({
                            message: t('general.ERROR'),
                            description: t(`occurrences.${errorType}`),
                        });
                    }

                    if (validType && validSize && validNumber) {
                        if (
                            !fileListRef.current.find(
                                element => element.name === f.name,
                            )
                        ) {
                            fileListRef.current.push(f);
                        }
                        newFiles.push(f.originFileObj!);
                    }
                }
            });

            onChange(newFiles as any);
        } else onChange(fileList.length ? fileList[0].originFileObj! : null);
    };

    const removeFile = (index: number) => {
        let newFiles: (RcFile | IFileUploaderFile)[] = [];

        if (Array.isArray(value)) newFiles = value;
        else if (value) newFiles = [value];

        const { uid } = newFiles[index] as any;

        if (uid) {
            const indexToRemove = fileListRef.current.findIndex(
                e => e.originFileObj!.uid === uid,
            );
            fileListRef.current.splice(indexToRemove, 1);
        }

        newFiles.splice(index, 1);

        if (multiple) onChange(newFiles as any);
        else onChange(newFiles.length ? (newFiles[0] as any) : null);
    };

    let resolvedFiles: filesType = [];

    if (Array.isArray(value)) resolvedFiles = value;
    else if (value) resolvedFiles = [value];

    return (
        <InputErrorWrapper
            error={error}
            className={classNames('file-uploader-error', className, {
                error,
            })}
        >
            <div className='file-uploader'>
                <Dragger
                    disabled={disabled}
                    multiple={multiple}
                    accept={accept}
                    customRequest={({ onSuccess }) => onSuccess!('ok')}
                    onChange={onFileChanges}
                    showUploadList={false}
                    fileList={fileListRef.current}
                    className='file-uploader__dragger'
                    {...rest}
                >
                    <div className='file-uploader__dragger__header'>
                        <img src={UploaderIcon} alt='Uploader Icon' />
                    </div>
                </Dragger>
                <p className='file-uploader__dragger__description'>
                    {t('form.GALLERY_IMAGE')}
                </p>
                {resolvedFiles.length !== 0 && (
                    <div className='file-uploader__separator' />
                )}
                <div className='file-uploader__content'>
                    <div className='file-uploader__content__list'>
                        {resolvedFiles.map((f, i) => (
                            <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={i}
                                className='file-uploader__content__list__item'
                            >
                                <div>
                                    <span className='file-uploader__content__list__item__header'>
                                        <div className='file-uploader__content__list__item__header__icon'>
                                            <img
                                                src={UploaderIcon}
                                                alt='Item Icon'
                                            />
                                        </div>
                                        {f.name}
                                    </span>
                                    <div>
                                        {f.size && (
                                            <span className='file-uploader__content__list__item__size'>
                                                {niceBytes(f.size)}
                                            </span>
                                        )}
                                        {!disableFileRemove && (
                                            <div
                                                className='file-uploader__content__list__item__remove'
                                                onClick={() => removeFile(i)}
                                            >
                                                <img
                                                    src={UploadCrossIcon}
                                                    alt='Garbage icon'
                                                    className='cross'
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </InputErrorWrapper>
    );
}

export default FileUploader;
