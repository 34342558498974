import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import PageBanner from 'components/PageBanner';
import Presidents from './components/Presidents';

function PresidentsPage() {
    const { t } = useTranslation();

    return (
        <div className='presidents-page'>
            <PageBanner
                title={t('menu.ELECTED')}
                primaryPage={false}
                previous
            />
            <Presidents />
        </div>
    );
}

export default PresidentsPage;
