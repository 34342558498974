/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAccount } from 'providers/AccountProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNewNotifications } from 'providers/NewNotificationsProvider';
import NotificationIcon from 'assets/icons/notification_icon.svg';
import { motion } from 'framer-motion';
import './styles.scss';
import PullToRefresh from 'react-simple-pull-to-refresh';
import PageBanner from 'components/PageBanner';
import { axios } from 'startup';
import config from 'config';
import { INotificationDTO } from 'DTOModels/notificationDTO';
import { Spin, notification } from 'antd';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import useElementOnScreen from 'hooks/useElementOnScreen';
import {
    notificationsPageContentMotion,
    notificationListItemMotion,
} from './animation';
import NotificationListItem from './components/NotificationListItem';

const top = 10;

function NotificationsPage() {
    const { t } = useTranslation();
    const { setAccount } = useAccount();
    const [loading, setLoading] = useState(false);
    const nextSkipRef = useRef(0);
    const navigate = useNavigate();
    const listEndMarkerRef = useRef<HTMLDivElement>(null);
    const hasMoreRef = useRef(true);
    const onScreen = useElementOnScreen(listEndMarkerRef, 0, false);
    const [items, setItems] = useState<INotificationDTO[]>([]);
    const { fetchNewNotifications } = useNewNotifications();

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const fetchList = useCallback(() => {
        const currentDate = new Date();
        nextSkipRef.current = 0;
        hasMoreRef.current = true;
        setLoading(true);
        axios
            .get(
                `${config.API_URL}/notifications?top=${top}&date=${currentDate}`,
            )
            .then(response => {
                setItems(
                    response.data.Notifications.map(
                        (item: INotificationDTO) => ({
                            ...item,
                        }),
                    ),
                );
                nextSkipRef.current += top;
                if (response.data.Notifications.length < top)
                    hasMoreRef.current = false;
                setLoading(false);
                fetchNewNotifications!(() => {});
            })
            .catch(() => {
                // force logout
                logout();
            });
    }, []);

    const fetchMore = () => {
        const lastId = items[items.length - 1].Id;
        const currentDate = new Date();
        axios
            .get(
                `${config.API_URL}/notifications?top=${top}&lastId=${lastId}&date=${currentDate}`,
            )
            .then(response => {
                nextSkipRef.current += top;
                setItems(m => [
                    ...m,
                    ...response.data.Notifications.filter(
                        (a: INotificationDTO) =>
                            !items.some(e => e.Id === a.Id),
                    ),
                ]);
                fetchNewNotifications!(() => {});
                if (response.data.Notifications.length < top)
                    hasMoreRef.current = false;
            })
            .catch(() => {
                // force logout
                logout();
            });
    };

    useEffect(() => {
        fetchList();
    }, []);

    useEffect(() => {
        if (onScreen && hasMoreRef.current) fetchMore();
    }, [onScreen]);

    return (
        <div className='notifications-page'>
            <PageBanner title={t('notifications.NOTIFICATIONS')} />
            <div className='notifications-page__content'>
                {loading && (
                    <div className='notifications-page__content__empty'>
                        <Spin className='' size='large' />
                    </div>
                )}
                {!loading && items.length === 0 && (
                    <motion.div
                        {...notificationsPageContentMotion}
                        className='notifications-page__content__empty'
                    >
                        <img src={NotificationIcon} alt='Notification Icon' />
                        <p>{t('notifications.NO_NOTIFICATIONS')}</p>
                    </motion.div>
                )}
                {!loading && items.length > 0 && (
                    <PullToRefresh
                        onRefresh={async () => fetchList()}
                        pullingContent=''
                        resistance={1.5}
                    >
                        <motion.div
                            {...notificationsPageContentMotion}
                            className='notifications-page__content__list'
                        >
                            {items.map(
                                (
                                    notificationItem: INotificationDTO,
                                    index: number,
                                ) => {
                                    return (
                                        <motion.div
                                            {...notificationListItemMotion}
                                            className='notifications-page__content__list__item'
                                            key={index}
                                        >
                                            <NotificationListItem
                                                notification={notificationItem}
                                            />
                                        </motion.div>
                                    );
                                },
                            )}
                        </motion.div>
                    </PullToRefresh>
                )}
            </div>
            <div
                ref={listEndMarkerRef}
                className='notifications-page__end-marker'
            />
        </div>
    );
}

export default NotificationsPage;
