/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'providers/AccountProvider';
import { useNavigate } from 'react-router-dom';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import { Spin, notification } from 'antd';
import { motion } from 'framer-motion';
import EmptyIcon from 'assets/icons/notification_icon.svg';
import './styles.scss';
import { axios } from 'startup';
import config from 'config';
import { IPlaceDTO } from 'DTOModels/placeDTO';
import { placesToVisitMotion, placesToVisitListItemMotion } from './animation';
import PlacesToVisitListItem from './components/PlacesToVisitListItem';

const top = 10;

function PlacesToVisit() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true);
    const { setAccount } = useAccount();
    const [items, setItems] = useState<IPlaceDTO[]>([]);

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const fetchList = useCallback(() => {
        const currentDate = new Date();
        setHasMore(true);
        setLoading(true);
        axios
            .get(`${config.API_URL}/places?top=${top}&date=${currentDate}`)
            .then(response => {
                setItems(
                    response.data.Places.map((item: IPlaceDTO) => ({
                        ...item,
                    })),
                );
                if (response.data.Total <= top) setHasMore(false);
                setLoading(false);
            })
            .catch(() => {
                // force logout
                logout();
            });
    }, []);

    const fetchMore = () => {
        const lastId = items[items.length - 1].Id;
        const currentDate = new Date();
        axios
            .get(
                `${config.API_URL}/places?top=${top}&lastId=${lastId}&date=${currentDate}`,
            )
            .then(response => {
                setItems(m => [
                    ...m,
                    ...response.data.Places.filter(
                        (a: IPlaceDTO) => !items.some(e => e.Id === a.Id),
                    ),
                ]);
                if (response.data.Total <= top) setHasMore(false);
            })
            .catch(() => {
                // force logout
                logout();
            });
    };

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <div className='places-to-visit'>
            <div className='places-to-visit__content'>
                {loading && (
                    <div className='places-to-visit__content__empty'>
                        <Spin className='' size='large' />
                    </div>
                )}
                {!loading && items.length === 0 && (
                    <motion.div
                        {...placesToVisitMotion}
                        className='places-to-visit__content__empty'
                    >
                        <img src={EmptyIcon} alt='Empty Icon' />
                        <p>
                            {t(
                                'accommodationAndGastronomy.NO_PLACES_REGISTERED',
                            )}
                        </p>
                    </motion.div>
                )}
                {!loading && items.length > 0 && (
                    <motion.div
                        {...placesToVisitMotion}
                        className='places-to-visit__content__list'
                    >
                        {items.map((placeItem: IPlaceDTO, index: number) => {
                            return (
                                <motion.div
                                    {...placesToVisitListItemMotion}
                                    className='places-to-visit__content__list__item'
                                    key={index}
                                >
                                    <PlacesToVisitListItem
                                        place={placeItem}
                                        className={`${
                                            index % 2 !== 0 ? 'grey' : ''
                                        }`}
                                    />
                                </motion.div>
                            );
                        })}
                    </motion.div>
                )}
            </div>
            {!loading && items.length > 0 && hasMore && (
                <button
                    type='button'
                    className='places-to-visit__button'
                    onClick={() => {
                        fetchMore();
                    }}
                >
                    {t('general.SEE_MORE')}
                </button>
            )}
        </div>
    );
}

export default PlacesToVisit;
