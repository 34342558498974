import SiteButton from 'components/SiteButton';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import FacebookIcon from 'assets/icons/facebook_icon.png';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { SESSION_COOKIE_KEY, setCookie } from 'services/cookies';
import './styles.scss';
import { useForm } from 'linkwithus';
import { IFacebookLoginForm } from './interface';

function FBLogin() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const loginSuccess = (token: string) => {
        notification.success({
            message: t('general.SUCCESS'),
            description: t('login.LOGIN_SUCCESS'),
        });
        // login via facebook token stays active for 30 days
        setCookie(SESSION_COOKIE_KEY, token, 30);
        setTimeout(() => {
            navigate('/');
        }, 500);
    };

    const { updateForm, form, submit } = useForm<IFacebookLoginForm>({
        url: '/accounts/facebook-login',
        onSuccess: (data: any) => {
            const token = data.Token;
            if (token) loginSuccess(token);
        },
        onError: (data: any, error: any) => {
            const errorCode = Number(error.errorCode) || 0;
            notification.error({
                message: t(`general.ERROR`),
                description: t(`errors.${errorCode}`),
            });
        },
    });

    const responseFacebook = (response: any) => {
        if (response.accessToken && response.email && response.name) {
            updateForm('email', response.email);
            updateForm('name', response.name);
        } else {
            notification.error({
                message: t('general.ERROR'),
                description: t('facebookLogin.ERROR'),
            });
        }
    };

    useEffect(() => {
        if (form.email && form.name) {
            submit();
        }
    }, [form]);

    return (
        <div className='container'>
            <FacebookLogin
                appId='1118422115538105'
                autoLoad={false}
                fields='name,email,picture'
                scope='public_profile,email,user_friends'
                callback={responseFacebook}
                icon='fa-facebook'
                render={renderProps => (
                    <SiteButton
                        text={t('general.FACEBOOK')}
                        onClick={renderProps.onClick}
                        type='primary'
                        icon={<img src={FacebookIcon} alt='Document Icon' />}
                        className='blue full-width'
                    />
                )}
                disableMobileRedirect
            />
        </div>
    );
}

export default FBLogin;
