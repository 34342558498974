import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import NotFoundIcon from 'assets/icons/page_not_found_icon.svg';
import './styles.scss';
import SiteButton from 'components/SiteButton';
import { sectionContentMotion } from './animation';

function NotFoundPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='page404'>
            <motion.div {...sectionContentMotion} className='page404__content'>
                <p className='page404__content__title'>{t('notFound.TITLE')}</p>
                <p className='page404__content__sub-title'>
                    {t('notFound.SUBTITLE')}
                </p>
                <p className='page404__content__description'>
                    {t('notFound.DESCRIPTION')}
                </p>
                <SiteButton
                    text={t('notFound.BUTTON_TEXT')}
                    type='primary'
                    className='full-width'
                    onClick={() => {
                        navigate('/');
                    }}
                />
            </motion.div>
            <img src={NotFoundIcon} alt='404 Icon' />
        </div>
    );
}

export default NotFoundPage;
