import React from 'react';
import './styles.scss';
import config from 'config';
import { IAccommodationListItem } from './interface';

function AccommodationListItem({ accommodation }: IAccommodationListItem) {
    return (
        <div className='accommodation-list-item'>
            <img
                src={`${config.API_URL}/accommodations/images/${accommodation.Id}`}
                alt='Item Img'
            />
            <p className='accommodation-list-item__title'>
                {accommodation.Accommodationlanguages[0].Name}
            </p>
            <p className='accommodation-list-item__description'>
                {accommodation.Contact.replace('.', ' ')}
            </p>
            <p className='accommodation-list-item__description'>
                {accommodation.Accommodationlanguages[0].Location}
            </p>
        </div>
    );
}

export default AccommodationListItem;
