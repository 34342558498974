import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { ReactComponent as GoBack } from 'assets/icons/arrow_icon.svg';
import { ReactComponent as ForgotPasswordIcon } from 'assets/icons/forgot_password_icon.svg';
import { motion } from 'framer-motion';
import SiteButton from 'components/SiteButton';
import { forgotPasswordPageContentMotion } from 'containers/ForgotPasswordPage/animation';
import { Input, useForm } from 'linkwithus';
import { emailRegex } from 'utils/regex';
import { IForgotPasswordStepProps } from 'containers/ForgotPasswordPage/interface';
import { IForgotPasswordStep1FormProps } from './interface';

function Step1({ nextStep, setToken, setEmail }: IForgotPasswordStepProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { register, registerSubmit, form } =
        useForm<IForgotPasswordStep1FormProps>({
            url: 'accounts/forgot-password/send-email',
            onSuccess: (data: any) => {
                setEmail(form.email);
                setToken(data.Token);
                nextStep();
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
            },
        });

    return (
        <motion.div
            {...forgotPasswordPageContentMotion}
            className='forgot-password-page__content'
        >
            <div className='forgot-password-page__content__header'>
                <button
                    className='forgot-password-page__content__header__button'
                    type='button'
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <GoBack className='forgot-password-page__content__header__button__go-back-icon' />
                </button>
                <p className='forgot-password-page__content__header__text'>
                    {t('forgotPassword.FORGOT_PASSWORD')}
                </p>
            </div>
            <div className='forgot-password-page__content__body'>
                <ForgotPasswordIcon />
                <p className='forgot-password-page__content__body__text'>
                    {t('forgotPassword.INSERT_EMAIL')}
                </p>
                <p className='forgot-password-page__content__body__label'>
                    {t('form.EMAIL')}
                </p>
                <Input
                    {...register('email', {
                        required: true,
                        regex: emailRegex,
                        resolveError: (val, error) => {
                            if (error.type === 'regex') {
                                return `${t('general.THE')} ${t(
                                    'form.EMAIL',
                                )} ${t('form.FORMAT_INVALID')}`;
                            }
                            return `${t('form.EMAIL')} ${t(
                                'form.REQUIRED_FIELD',
                            )}`;
                        },
                    })}
                    className='forgot-password-page__content__body__input-field'
                />
            </div>
            <div className='forgot-password-page__content__footer'>
                <SiteButton
                    text={t('general.SEND')}
                    type='primary'
                    className='full-width'
                    {...registerSubmit()}
                />
            </div>
        </motion.div>
    );
}

export default Step1;
