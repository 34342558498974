import React from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryBannerImage from 'assets/images/backgrounds/login_background.png';
import SecondaryBannerImage from 'assets/images/backgrounds/secondary_background.png';
import { ReactComponent as GoBack } from 'assets/icons/arrow_icon.svg';
import { motion } from 'framer-motion';
import './styles.scss';
import { bannerMotion } from './animation';
import { IPageBannerProps } from './interface';

function PageBanner({
    title,
    previous = false,
    primaryPage = true,
}: IPageBannerProps) {
    const navigate = useNavigate();
    return (
        <div className='page-banner'>
            {previous && (
                <button
                    className='page-banner__button'
                    type='button'
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <GoBack className='page-banner__button__go-back-icon' />
                </button>
            )}
            <img
                src={primaryPage ? PrimaryBannerImage : SecondaryBannerImage}
                alt='Banner Img'
            />
            <motion.p {...bannerMotion}>{title}</motion.p>
        </div>
    );
}

export default PageBanner;
