export const NewOccurrenceMotion = {
    initial: { opacity: 0, scale: 0.5 },
    animate: {
        opacity: [0, 1],
        scale: [0.5, 1],
        transition: {
            duration: 0.2,
            ease: 'easeIn',
        },
    },
};
