import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { motion } from 'framer-motion';
import ArrowIcon from 'assets/icons/menu_arrow_icon.svg';
import { IHamburgerProps } from './interface';
import {
    menuItem1Motion,
    menuItem2Motion,
    menuItem3Motion,
    menuItem4Motion,
    menuItem5Motion,
} from './animation';

function HamburgerMenu({ className }: IHamburgerProps) {
    const { t } = useTranslation();
    const location = useLocation();
    const [subAboutMenuVisibility, setSubAboutMenuVisibility] = useState(false);
    const [subTourismMenuVisibility, setSubTourismMenuVisibility] =
        useState(false);

    const changeSubTourismMenuVisibility = useCallback(() => {
        setSubAboutMenuVisibility(false);
        setSubTourismMenuVisibility(!subTourismMenuVisibility);
    }, [subTourismMenuVisibility]);

    const changeSubAboutMenuVisibility = useCallback(() => {
        setSubTourismMenuVisibility(false);
        setSubAboutMenuVisibility(!subAboutMenuVisibility);
    }, [subAboutMenuVisibility]);

    useEffect(() => {
        setSubTourismMenuVisibility(false);
        setSubAboutMenuVisibility(false);
    }, [location.pathname, className]);

    return (
        <div className={`hamburger-menu ${className || ''}`}>
            <p>{t('menu.COUNTY')}</p>
            <Link to='/about' className='hamburger-menu__link'>
                <p>{t('menu.ABOUT')}</p>
            </Link>
            <button
                type='button'
                className={`hamburger-menu__button ${
                    subAboutMenuVisibility ? 'active' : ''
                }`}
                onClick={changeSubAboutMenuVisibility}
            >
                <p>{t('menu.ENTITY')}</p>
                <img
                    src={ArrowIcon}
                    alt='Arrow icon'
                    className={`${subAboutMenuVisibility ? 'active' : ''}`}
                />
            </button>
            {subAboutMenuVisibility && (
                <div className='hamburger-menu__sub-menu'>
                    <Link
                        to='/current-president'
                        className='hamburger-menu__sub-menu__link'
                    >
                        <motion.p {...menuItem1Motion}>
                            {t('menu.PRESIDENT')}
                        </motion.p>
                    </Link>
                    <Link
                        to='/assembly-president'
                        className='hamburger-menu__sub-menu__link'
                    >
                        <motion.p {...menuItem2Motion}>
                            {t('menu.ASSEMBLY_PRESIDENT')}
                        </motion.p>
                    </Link>
                    <Link
                        to='/aldermen'
                        className='hamburger-menu__sub-menu__link'
                    >
                        <motion.p {...menuItem3Motion}>
                            {t('menu.DEPUTIES')}
                        </motion.p>
                    </Link>
                    <Link
                        to='/elected'
                        className='hamburger-menu__sub-menu__link'
                    >
                        <motion.p {...menuItem4Motion}>
                            {t('menu.ALDERMEN')}
                        </motion.p>
                    </Link>
                    <Link
                        to='/presidents'
                        className='hamburger-menu__sub-menu__link'
                    >
                        <motion.p {...menuItem5Motion}>
                            {t('menu.ELECTED')}
                        </motion.p>
                    </Link>
                </div>
            )}
            <Link
                to='/notifications'
                className={`hamburger-menu__link ${
                    subAboutMenuVisibility ? 'top-border' : ''
                }`}
            >
                <p>{t('general.NOTIFICATIONS')}</p>
            </Link>
            <Link to='/warnings' className='hamburger-menu__link'>
                <p>{t('menu.WARNINGS')}</p>
            </Link>
            <button
                type='button'
                className={`hamburger-menu__button ${
                    subTourismMenuVisibility ? 'active' : ''
                }`}
                onClick={changeSubTourismMenuVisibility}
            >
                <p className='last'>{t('menu.TOURISM')}</p>
                <img
                    src={ArrowIcon}
                    alt='Arrow icon'
                    className={`${subTourismMenuVisibility ? 'active' : ''}`}
                />
            </button>
            {subTourismMenuVisibility && (
                <div className='hamburger-menu__sub-menu'>
                    <Link
                        to='/accommodations'
                        className='hamburger-menu__sub-menu__link'
                    >
                        <motion.p {...menuItem1Motion}>
                            {t('menu.ACCOMMODATION')}
                        </motion.p>
                    </Link>
                    <Link
                        to='/restaurants'
                        className='hamburger-menu__sub-menu__link'
                    >
                        <motion.p {...menuItem2Motion}>
                            {t('menu.RESTAURANTS')}
                        </motion.p>
                    </Link>
                    <Link
                        to='/rent-a-car'
                        className='hamburger-menu__sub-menu__link'
                    >
                        <motion.p {...menuItem3Motion}>
                            {t('menu.RENT_A_CAR')}
                        </motion.p>
                    </Link>
                    <Link
                        to='/travel'
                        className='hamburger-menu__sub-menu__link'
                    >
                        <motion.p {...menuItem4Motion}>
                            {t('menu.TRAVEL_AGENCIES')}
                        </motion.p>
                    </Link>
                    <Link
                        to='/visit'
                        className='hamburger-menu__sub-menu__link'
                    >
                        <motion.p {...menuItem5Motion}>
                            {t('menu.LANDMARKS')}
                        </motion.p>
                    </Link>
                </div>
            )}
            <p>{t('menu.TALK_WITH_US')}</p>
            <Link to='/suggestions' className='hamburger-menu__link'>
                <p>{t('menu.SUGGESTIONS')}</p>
            </Link>
            <Link to='/contacts' className='hamburger-menu__link'>
                <p>{t('menu.CONTACTS')}</p>
            </Link>
            <Link to='/privacy-policy' className='hamburger-menu__link'>
                <p>{t('menu.PRIVACY_POLICY')}</p>
            </Link>
        </div>
    );
}

export default HamburgerMenu;
