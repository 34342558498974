import React from 'react';
import HomePage from 'containers/HomePage';
import PageBackground from 'containers/PageBackground';
import LoginPage from 'containers/LoginPage';
import RequiresAuthentication from 'containers/RequiresAuthentication';
import RequiresAnonymous from 'containers/RequiresAnonymous';
import SignUpPage from 'containers/SignUpPage';
import Menu from 'containers/Menu';
import Footer from 'containers/Footer';
import ContactPage from 'containers/ContactPage';
import SuggestionsPage from 'containers/SuggestionsPage';
import AboutPage from 'containers/AboutPage';
import OccurrencePage from 'containers/OccurrencePage';
import RegisterOccurrencePage from 'containers/RegisterOccurrencePage';
import SettingsPage from 'containers/SettingsPage';
import ForgotPasswordPage from 'containers/ForgotPasswordPage';
import ConfirmAccountPage from 'containers/ConfirmAccountPage';
import PrivacyPolicyPage from 'containers/PrivacyPolicyPage';
import OptionalAuthentication from 'containers/OptionalAuthentication';
import NotificationsPage from 'containers/NotificationsPage';
import NotificationPage from 'containers/NotificationPage';
import AccommodationsPage from 'containers/AccommodationsPage';
import RestaurantsPage from 'containers/RestaurantsPage';
import NotFoundPage from 'containers/404Page';
import EventsPage from 'containers/EventsPage';
import RentACarPage from 'containers/RentACarPage';
import TravelAgenciesPage from 'containers/TravelAgenciesPage';
import AldermenPage from 'containers/AldermenPage';
import DeputiesPage from 'containers/DeputiesPage';
import PresidentsPage from 'containers/PresidentsPage';
import AssemblyPresidentPage from 'containers/AssemblyPresidentPage';
import PresidentPage from 'containers/PresidentPage';
import PlacesToVisitPage from 'containers/PlacesToVisitPage';

export interface IRoute {
    url: string;
    component: React.ReactElement;
}

export const Routes: IRoute[] = [
    {
        url: '/',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <HomePage />
                    <Footer tab={2} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/occurrence/:id',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <OccurrencePage />
                    <Footer tab={2} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/new-occurrence',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <RegisterOccurrencePage />
                    <Footer tab={2} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/notifications',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <NotificationsPage />
                    <Footer tab={1} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/notification/:id',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <NotificationPage />
                    <Footer tab={1} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/settings',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <SettingsPage />
                    <Footer tab={3} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/contacts',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <ContactPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/accommodations',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <AccommodationsPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/rent-a-car',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <RentACarPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/travel',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <TravelAgenciesPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/restaurants',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <RestaurantsPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/warnings',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <EventsPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/visit',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <PlacesToVisitPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/suggestions',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <SuggestionsPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/about',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <AboutPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/aldermen',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <AldermenPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/presidents',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <PresidentsPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/elected',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <DeputiesPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/assembly-president',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <AssemblyPresidentPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/current-president',
        component: (
            <RequiresAuthentication>
                <PageBackground>
                    <Menu />
                    <PresidentPage />
                    <Footer tab={4} />
                </PageBackground>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/login',
        component: (
            <RequiresAnonymous>
                <PageBackground>
                    <LoginPage />
                </PageBackground>
            </RequiresAnonymous>
        ),
    },
    {
        url: '/forgot-password',
        component: (
            <RequiresAnonymous>
                <PageBackground>
                    <ForgotPasswordPage />
                </PageBackground>
            </RequiresAnonymous>
        ),
    },
    {
        url: '/privacy-policy',
        component: (
            <OptionalAuthentication>
                <PageBackground>
                    <Menu />
                    <PrivacyPolicyPage />
                    <Footer tab={4} />
                </PageBackground>
            </OptionalAuthentication>
        ),
    },
    {
        url: '/sign-up',
        component: (
            <RequiresAnonymous>
                <PageBackground>
                    <SignUpPage />
                </PageBackground>
            </RequiresAnonymous>
        ),
    },
    {
        url: '/confirm-account/:token',
        component: (
            <RequiresAnonymous>
                <PageBackground>
                    <ConfirmAccountPage />
                </PageBackground>
            </RequiresAnonymous>
        ),
    },
    {
        url: '*',
        component: (
            <OptionalAuthentication>
                <PageBackground>
                    <Menu />
                    <NotFoundPage />
                    <Footer tab={4} />
                </PageBackground>
            </OptionalAuthentication>
        ),
    },
];
