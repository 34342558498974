import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { notification } from 'antd';
import { motion } from 'framer-motion';
import SiteButton from 'components/SiteButton';
import {
    Checkbox,
    Input,
    PasswordInput,
    PhoneInput,
    useForm,
} from 'linkwithus';
import { emailRegex, passwordRegex, phoneRegex } from 'utils/regex';
import FBLogin from 'components/FacebookLogin';
import { ISignUpForm, ISignUpFormProps } from './interface';
import { SignUpFormMotion, SignUpHeaderMotion } from '../animation';

const INITIAL_FORM = {
    isBackOffice: false,
    privacyPolicy: false,
};

function SignUpForm({ onSuccess }: ISignUpFormProps) {
    const { t } = useTranslation();

    const { register, registerSubmit, form } = useForm<ISignUpForm>({
        url: '/accounts/signup',
        initialForm: INITIAL_FORM,
        onSuccess,
        onError: (data, error: any) => {
            const errorCode = Number(error.errorCode) || 0;
            notification.error({
                message: t(`general.ERROR`),
                description: t(`errors.${errorCode}`),
            });
        },
    });

    const { password } = form;

    return (
        <div className='sign-up-page__creating-account'>
            <div className='sign-up-page__creating-account__spacer' />
            <motion.p
                {...SignUpHeaderMotion}
                className='sign-up-page__creating-account__title'
            >
                {t('signup.CREATE_ACCOUNT')}
            </motion.p>
            <motion.p
                {...SignUpHeaderMotion}
                className='sign-up-page__creating-account__description'
            >
                {t('signup.REGISTER_ACCOUNT_INFO')}
            </motion.p>
            <motion.div
                {...SignUpFormMotion}
                className='sign-up-page__creating-account__form'
            >
                <p className='sign-up-page__creating-account__form__label'>
                    {t('form.NAME')}
                </p>
                <Input
                    {...register('name', {
                        required: true,
                        resolveError: () =>
                            `${t('form.NAME')} ${t('form.REQUIRED_FIELD')}`,
                    })}
                    className='sign-up-page__creating-account__form__input-field'
                />
                <p className='sign-up-page__creating-account__form__label'>
                    {t('form.CONTACT')}
                </p>
                <PhoneInput
                    {...register('contact', {
                        required: true,
                        regex: phoneRegex,
                        resolveError: (val, error) => {
                            if (error.type === 'regex') {
                                return `${t('general.THE')} ${t(
                                    'form.CONTACT',
                                )} ${t('form.FORMAT_INVALID')}`;
                            }
                            return `${t('form.CONTACT')} ${t(
                                'form.REQUIRED_FIELD',
                            )}`;
                        },
                    })}
                    className='sign-up-page__creating-account__form__input-field'
                />
                <p className='sign-up-page__creating-account__form__label'>
                    {t('form.EMAIL')}
                </p>
                <Input
                    {...register('email', {
                        required: true,
                        regex: emailRegex,
                        resolveError: (val, error) => {
                            if (error.type === 'regex') {
                                return `${t('general.THE')} ${t(
                                    'form.EMAIL',
                                )} ${t('form.FORMAT_INVALID')}`;
                            }
                            return `${t('form.EMAIL')} ${t(
                                'form.REQUIRED_FIELD',
                            )}`;
                        },
                    })}
                    className='sign-up-page__creating-account__form__input-field'
                />
                <p className='sign-up-page__creating-account__form__label'>
                    {t('form.PASSWORD')}
                </p>
                <PasswordInput
                    {...register('password', {
                        required: true,
                        regex: passwordRegex,
                        resolveError: (val, error) => {
                            if (error.type === 'regex') {
                                return `${t('form.PASSWORD')} ${t(
                                    'form.PASSWORD_FORMAT',
                                )}`;
                            }
                            return `${t('form.PASSWORD')} ${t(
                                'form.REQUIRED_FIELD',
                            )}`;
                        },
                    })}
                    className='sign-up-page__creating-account__form__input-field'
                />
                <p className='sign-up-page__creating-account__form__label'>
                    {t('form.CONFIRM_PASSWORD')}
                </p>
                <PasswordInput
                    {...register('confirmPassword', {
                        required: true,
                        isValid: v => v === password,
                        resolveError: (v, err) =>
                            err.type === 'custom-validation'
                                ? `${t('form.PASSWORD')} ${t(
                                      'form.REQUIRED_FIELD',
                                  )}`
                                : t('form.NON_MATCHING_PASSWORDS'),
                    })}
                    className='sign-up-page__creating-account__form__last-input-field'
                />
                <Checkbox
                    {...register('privacyPolicy', {
                        required: true,
                        resolveError: () =>
                            `${t('form.CHECKBOX')} ${t('form.REQUIRED')}`,
                    })}
                    className='sign-up-page__creating-account__form__input-field checkbox'
                >
                    <p>
                        {t('form.READ_AGREE')}
                        <Link to='/privacy-policy'>
                            <span>{t('form.PRIVACY_POLICY')}</span>
                        </Link>
                    </p>
                </Checkbox>
                <SiteButton
                    text={t('signup.CREATE_ACCOUNT')}
                    type='primary'
                    className='uppercase full-width'
                    {...registerSubmit()}
                />
                <p className='sign-up-page__creating-account__form__question'>
                    {t('general.OR')}
                </p>
                <FBLogin />
            </motion.div>
            <motion.div
                className='sign-up-page__creating-account__footer'
                {...SignUpFormMotion}
            >
                <p>{t('signup.ACCOUNT_LOGIN_QUESTION')}</p>
                <Link to='/login' className=''>
                    <span className='sign-up-page__creating-account__footer__link'>
                        {t('general.LOGIN')}
                    </span>
                </Link>
            </motion.div>
        </div>
    );
}

export default SignUpForm;
