/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAccount } from 'providers/AccountProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import OccurrenceIcon from 'assets/icons/occurrence_icon.svg';
import { motion } from 'framer-motion';
import './styles.scss';
import PullToRefresh from 'react-simple-pull-to-refresh';
import PageBanner from 'components/PageBanner';
import { axios } from 'startup';
import config from 'config';
import { IOccurrenceDTO } from 'DTOModels/occurrenceDTO';
import { Spin, notification } from 'antd';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import useElementOnScreen from 'hooks/useElementOnScreen';
import { homePageContentMotion, occurrenceListItemMotion } from './animation';
import OccurrenceListItem from './components/OccurrenceListItem';

const top = 10;

function HomePage() {
    const { t } = useTranslation();
    const { setAccount } = useAccount();
    const [loading, setLoading] = useState(false);
    const nextSkipRef = useRef(0);
    const navigate = useNavigate();
    const listEndMarkerRef = useRef<HTMLDivElement>(null);
    const hasMoreRef = useRef(true);
    const onScreen = useElementOnScreen(listEndMarkerRef, 0, false);
    const [items, setItems] = useState<IOccurrenceDTO[]>([]);

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const fetchList = useCallback(() => {
        const currentDate = new Date();
        nextSkipRef.current = 0;
        hasMoreRef.current = true;
        setLoading(true);
        axios
            .get(`${config.API_URL}/occurrences?top=${top}&date=${currentDate}`)
            .then(response => {
                setItems(
                    response.data.Occurrences.map((item: IOccurrenceDTO) => ({
                        ...item,
                    })),
                );
                nextSkipRef.current += top;
                if (response.data.Total <= top) hasMoreRef.current = false;
                setLoading(false);
            })
            .catch(() => {
                // force logout
                logout();
            });
    }, []);

    const fetchMore = useCallback(() => {
        const currentDate = new Date();
        axios
            .get(
                `${config.API_URL}/occurrences?top=${top}&skip=${nextSkipRef.current}&date=${currentDate}`,
            )
            .then(response => {
                nextSkipRef.current += top;
                setItems(m => [
                    ...m,
                    ...response.data.Occurrences.filter(
                        (a: IOccurrenceDTO) => !items.some(e => e.Id === a.Id),
                    ),
                ]);
                if (response.data.Total <= top) hasMoreRef.current = false;
            })
            .catch(() => {
                // force logout
                logout();
            });
    }, []);

    useEffect(() => {
        fetchList();
    }, []);

    useEffect(() => {
        if (onScreen && hasMoreRef.current) fetchMore();
    }, [onScreen]);

    return (
        <div className='home-page'>
            <PageBanner title={t('occurrences.OCCURRENCES')} />
            <div className='home-page__content'>
                {loading && (
                    <div className='home-page__content__empty'>
                        <Spin className='' size='large' />
                    </div>
                )}
                {!loading && items.length === 0 && (
                    <motion.div
                        {...homePageContentMotion}
                        className='home-page__content__empty'
                    >
                        <img src={OccurrenceIcon} alt='Occurrence Icon' />
                        <p>{t('occurrences.NO_OCCURRENCES')}</p>
                    </motion.div>
                )}
                {!loading && items.length > 0 && (
                    <PullToRefresh
                        onRefresh={async () => fetchList()}
                        pullingContent=''
                        resistance={1.5}
                    >
                        <motion.div
                            {...homePageContentMotion}
                            className='home-page__content__list'
                        >
                            {items.map(
                                (occurrence: IOccurrenceDTO, index: number) => {
                                    return (
                                        <motion.div
                                            {...occurrenceListItemMotion}
                                            className='home-page__content__list__item'
                                            key={index}
                                        >
                                            <OccurrenceListItem
                                                occurrence={occurrence}
                                            />
                                        </motion.div>
                                    );
                                },
                            )}
                        </motion.div>
                    </PullToRefresh>
                )}
            </div>
            <div ref={listEndMarkerRef} className='home-page__end-marker' />
        </div>
    );
}

export default HomePage;
