import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import PageBanner from 'components/PageBanner';
import AssemblyPresident from './components/AssemblyPresident';

function AssemblyPresidentPage() {
    const { t } = useTranslation();

    return (
        <div className='assembly-president-page'>
            <PageBanner
                title={t('menu.ASSEMBLY_PRESIDENT')}
                primaryPage={false}
                previous
            />
            <AssemblyPresident />
        </div>
    );
}

export default AssemblyPresidentPage;
