import React from 'react';
import './styles.scss';
import config from 'config';
import { IGastronomyListItem } from './interface';

function GastronomyListItem({ gastronomy }: IGastronomyListItem) {
    return (
        <div className='gastronomy-list-item'>
            <img
                src={`${config.API_URL}/gastronomy/images/${gastronomy.Id}`}
                alt='Item Img'
            />
            <p className='gastronomy-list-item__title'>
                {gastronomy.Gastronomylanguages[0].Name}
            </p>
            <p className='gastronomy-list-item__description'>
                {gastronomy.Contact.replace('.', ' ')}
            </p>
            <p className='gastronomy-list-item__description'>
                {gastronomy.Gastronomylanguages[0].Description}
            </p>
        </div>
    );
}

export default GastronomyListItem;
