import React, { useEffect } from 'react';
import { axios } from 'startup';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import './styles.scss';
import LoadingPage from 'containers/LoadingPage';

function ConfirmAccountPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { token } = useParams();

    useEffect(() => {
        if (!token) {
            navigate('/login');
            notification.error({
                message: t('general.ERROR'),
                description: t('errors.0'),
            });
        }
        axios
            .post(`/accounts/confirm-account/${token}`)
            .then(() => {
                // success notification and send to /login
                setTimeout(() => {
                    navigate('/login');
                }, 250);
                notification.success({
                    message: t('general.SUCCESS'),
                    description: t('general.ACCOUNT_ACTIVATED'),
                });
            })
            .catch(() => {
                // error notification and send to /login
                setTimeout(() => {
                    navigate('/login');
                }, 250);
                notification.error({
                    message: t('general.ERROR'),
                    description: t('errors.0'),
                });
            });
    }, []);

    return <LoadingPage />;
}

export default ConfirmAccountPage;
