import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import OccurrenceIcon from 'assets/icons/occurrence_list_icon.svg';
import './styles.scss';
import moment from 'moment';
import { IOccurrenceListItem } from './interface';

function OccurrenceListItem({ occurrence }: IOccurrenceListItem) {
    const { t } = useTranslation();

    return (
        <div className='occurrence-list-item'>
            <Link
                to={`/occurrence/${occurrence.Id}`}
                className='occurrence-list-item__content'
            >
                <div className='occurrence-list-item__content__left-column'>
                    <div className='occurrence-list-item__content__left-column__circle'>
                        <img src={OccurrenceIcon} alt='Icon' />
                        <div
                            className={`occurrence-list-item__content__left-column__circle__status ${
                                occurrence.State === 0 ? 'pending' : 'concluded'
                            }`}
                        />
                    </div>
                    <div className='occurrence-list-item__content__left-column__text'>
                        <p className='occurrence-list-item__content__left-column__text__title'>
                            {t('occurrences.OCCURRENCE')}{' '}
                            {occurrence.State === 0
                                ? t('occurrences.PENDING')
                                : t('occurrences.CONCLUDED')}
                        </p>
                        <p className='occurrence-list-item__content__left-column__text__description'>
                            {occurrence.Description}
                        </p>
                    </div>
                </div>
                <div className='occurrence-list-item__content__right-column'>
                    {moment(occurrence.CreationDate).format('D/MM/YYYY')}
                </div>
            </Link>
        </div>
    );
}

export default OccurrenceListItem;
