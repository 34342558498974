import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAccount } from 'providers/AccountProvider';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { ReactComponent as GoBack } from 'assets/icons/arrow_icon.svg';
import './styles.scss';
import { useParams, useNavigate } from 'react-router-dom';
import { axios } from 'startup';
import config from 'config';
import { Spin, notification } from 'antd';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { IOccurrencePageDTO } from 'DTOModels/occurrenceDTO';
import { timeSince } from 'utils/dates';
import moment from 'moment';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import SwipeableCarousel from 'components/SwipeableCarousel';
import { occurrencePageContentMotion } from './animation';

function OccurrencePage() {
    const { t } = useTranslation();
    const { id } = useParams();
    const { setAccount } = useAccount();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [occurrence, setOccurrence] = useState<IOccurrencePageDTO>();
    const [objectTime, setObjectTime] = useState({ time: 1, type: 'MINUTE' });
    const carouselRef = useRef<HTMLDivElement>(null);

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const refreshPage = useCallback(() => {
        const currentDate = new Date();
        setLoading(true);
        axios
            .get(`${config.API_URL}/occurrences/page/${id}?date=${currentDate}`)
            .then((res: any) => {
                const occurrenceInfo = res.data.Occurrence;
                setOccurrence({
                    ...occurrenceInfo,
                });
                setLoading(false);
            })
            .catch(() => {
                // force logout
                logout();
            });
    }, []);

    // make the request everytime the occurrenceId changes
    useEffect(() => {
        refreshPage();
    }, [id]);

    useEffect(() => {
        if (occurrence && occurrence.lastUpdateDate)
            setObjectTime(timeSince(occurrence.lastUpdateDate));
    }, [occurrence]);

    return (
        <div className='occurrence-page'>
            <div className='occurrence-page__content'>
                <PullToRefresh
                    onRefresh={async () => refreshPage()}
                    pullingContent=''
                    resistance={1.5}
                >
                    {loading || !occurrence ? (
                        <div className='occurrence-page__content__empty'>
                            <Spin className='' size='large' />
                        </div>
                    ) : (
                        <motion.div
                            {...occurrencePageContentMotion}
                            className='occurrence-page__content__occurrence'
                        >
                            <div className='occurrence-page__content__occurrence__block'>
                                <button
                                    type='button'
                                    onClick={() => {
                                        navigate('/');
                                    }}
                                >
                                    <GoBack />
                                </button>
                                <div className='occurrence-page__content__occurrence__block__status'>
                                    <div
                                        className={`occurrence-page__content__occurrence__block__status__circle ${
                                            occurrence.state === 0
                                                ? 'pending'
                                                : 'concluded'
                                        }`}
                                    />
                                    <div className='occurrence-page__content__occurrence__block__status__text'>
                                        <p className='occurrence-page__content__occurrence__block__status__text__title'>
                                            {t('occurrences.OCCURRENCE')}{' '}
                                            {occurrence.state === 0
                                                ? t('occurrences.PENDING')
                                                : t('occurrences.CONCLUDED')}
                                        </p>
                                        {occurrence.lastUpdateDate && (
                                            <p className='occurrence-page__content__occurrence__block__status__text__description'>
                                                {t('occurrences.LAST_UPDATE')}{' '}
                                                {t('occurrences.IN')}{' '}
                                                {objectTime.time}{' '}
                                                {t(
                                                    `general.${objectTime.type}`,
                                                )}{' '}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='occurrence-page__content__occurrence__block'>
                                <p className='occurrence-page__content__occurrence__block__title'>
                                    {t('occurrences.CREATION_DATE')}
                                </p>
                                <p className='occurrence-page__content__occurrence__block__description'>
                                    {moment(occurrence.creationDate).format(
                                        'D/MM/YYYY',
                                    )}
                                </p>
                            </div>
                            <div className='occurrence-page__content__occurrence__block'>
                                <p className='occurrence-page__content__occurrence__block__title'>
                                    {t('form.ADDRESS')}
                                </p>
                                <p className='occurrence-page__content__occurrence__block__description'>
                                    {occurrence.address}
                                </p>
                            </div>
                            <div className='occurrence-page__content__occurrence__block'>
                                <p className='occurrence-page__content__occurrence__block__title'>
                                    {t('occurrences.TYPE_OF_OCCURRENCE')}
                                </p>
                                <p className='occurrence-page__content__occurrence__block__description'>
                                    {occurrence.type}
                                </p>
                            </div>
                            <div className='occurrence-page__content__occurrence__block'>
                                <p className='occurrence-page__content__occurrence__block__title'>
                                    {t('form.DESCRIPTION')}
                                </p>
                                <p className='occurrence-page__content__occurrence__block__description'>
                                    {occurrence.description}
                                </p>
                            </div>
                            {occurrence.photos && occurrence.photos.length > 0 && (
                                <div
                                    ref={carouselRef}
                                    className='occurrence-page__content__occurrence__block carousel-block'
                                >
                                    <p className='occurrence-page__content__occurrence__block__title'>
                                        {t('occurrences.IMAGES_OF_OCCURRENCE')}
                                    </p>
                                    <SwipeableCarousel
                                        elements={occurrence.photos}
                                    />
                                </div>
                            )}
                            {occurrence.state === 1 && (
                                <>
                                    <div className='occurrence-page__content__occurrence__block no-border no-padding-bottom'>
                                        <p className='occurrence-page__content__occurrence__block__title'>
                                            {t('form.CONCLUDED_INFORMATION')}
                                        </p>
                                        <p className='occurrence-page__content__occurrence__block__description'>
                                            {occurrence.adminDescription}
                                        </p>
                                    </div>
                                    {occurrence.adminPhotos &&
                                        occurrence.adminPhotos.length > 0 && (
                                            <div
                                                ref={carouselRef}
                                                className='occurrence-page__content__occurrence__block no-border no-padding carousel-block'
                                            >
                                                <SwipeableCarousel
                                                    elements={
                                                        occurrence.adminPhotos
                                                    }
                                                    admin
                                                />
                                            </div>
                                        )}
                                </>
                            )}
                        </motion.div>
                    )}
                </PullToRefresh>
            </div>
        </div>
    );
}

export default OccurrencePage;
