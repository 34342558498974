/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'providers/AccountProvider';
import { useNavigate } from 'react-router-dom';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import { Spin, notification } from 'antd';
import { motion } from 'framer-motion';
import AccommodationIcon from 'assets/icons/accommodation_icon.svg';
import './styles.scss';
import { axios } from 'startup';
import config from 'config';
import { IAccommodationDTO } from 'DTOModels/accommodationDTO';
import {
    accommodationsMotion,
    accommodationsListItemMotion,
} from './animation';
import AccommodationListItem from './components/AccommodationListItem';

const top = 10;

function Accommodations() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true);
    const { setAccount } = useAccount();
    const [items, setItems] = useState<IAccommodationDTO[]>([]);

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const fetchList = useCallback(() => {
        const currentDate = new Date();
        setHasMore(true);
        setLoading(true);
        axios
            .get(
                `${config.API_URL}/accommodations?top=${top}&date=${currentDate}`,
            )
            .then(response => {
                setItems(
                    response.data.Accommodations.map(
                        (item: IAccommodationDTO) => ({
                            ...item,
                        }),
                    ),
                );
                if (response.data.Total <= top) setHasMore(false);
                setLoading(false);
            })
            .catch(() => {
                // force logout
                logout();
            });
    }, []);

    const fetchMore = () => {
        const lastId = items[items.length - 1].Id;
        const currentDate = new Date();
        axios
            .get(
                `${config.API_URL}/accommodations?top=${top}&lastId=${lastId}&date=${currentDate}`,
            )
            .then(response => {
                setItems(m => [
                    ...m,
                    ...response.data.Accommodations.filter(
                        (a: IAccommodationDTO) =>
                            !items.some(e => e.Id === a.Id),
                    ),
                ]);
                if (response.data.Total <= top) setHasMore(false);
            })
            .catch(() => {
                // force logout
                logout();
            });
    };

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <div className='accommodations'>
            <div className='accommodations__content'>
                {loading && (
                    <div className='accommodations__content__empty'>
                        <Spin className='' size='large' />
                    </div>
                )}
                {!loading && items.length === 0 && (
                    <motion.div
                        {...accommodationsMotion}
                        className='accommodations__content__empty'
                    >
                        <img src={AccommodationIcon} alt='Accommodation Icon' />
                        <p>
                            {t(
                                'accommodationAndGastronomy.NO_ACCOMMODATIONS_REGISTERED',
                            )}
                        </p>
                    </motion.div>
                )}
                {!loading && items.length > 0 && (
                    <motion.div
                        {...accommodationsMotion}
                        className='accommodations__content__list'
                    >
                        {items.map(
                            (
                                accommodationItem: IAccommodationDTO,
                                index: number,
                            ) => {
                                return (
                                    <motion.div
                                        {...accommodationsListItemMotion}
                                        className='accommodations__content__list__item'
                                        key={index}
                                    >
                                        <AccommodationListItem
                                            accommodation={accommodationItem}
                                        />
                                    </motion.div>
                                );
                            },
                        )}
                    </motion.div>
                )}
            </div>
            {!loading && items.length > 0 && hasMore && (
                <button
                    type='button'
                    className='accommodations__button'
                    onClick={() => {
                        fetchMore();
                    }}
                >
                    {t('general.SEE_MORE')}
                </button>
            )}
        </div>
    );
}

export default Accommodations;
