import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import './styles.scss';
import { motion } from 'framer-motion';
import SiteButton from 'components/SiteButton';
import { Checkbox, Input, PasswordInput, useForm } from 'linkwithus';
import { emailRegex } from 'utils/regex';
import {
    loginFooterMotion,
    loginFormMotion,
} from 'containers/LoginPage/animation';
import { SESSION_COOKIE_KEY, setCookie } from 'services/cookies';
import FBLogin from 'components/FacebookLogin';
import { ILoginForm } from './interface';

function LoginForm() {
    const { t } = useTranslation();
    const [remember, setRemember] = useState(false);
    const navigate = useNavigate();

    const loginSuccess = (token: string) => {
        notification.success({
            message: t('general.SUCCESS'),
            description: t('login.LOGIN_SUCCESS'),
        });
        setCookie(SESSION_COOKIE_KEY, token, remember ? 30 : undefined);
        setTimeout(() => {
            navigate('/');
        }, 500);
    };

    const { register, registerSubmit } = useForm<ILoginForm>({
        url: '/accounts/login',
        onSuccess: (data: any) => {
            const token = data.Token;
            if (token) loginSuccess(token);
        },
        onError: (data, error: any) => {
            const errorCode = Number(error.errorCode) || 0;
            notification.error({
                message: t(`general.ERROR`),
                description: t(`errors.${errorCode}`),
            });
        },
    });

    return (
        <div className='login-form'>
            <div className='login-form__spacer' />
            <motion.p {...loginFormMotion} className='login-form__title'>
                {t('login.GREETING')}
            </motion.p>
            <motion.p {...loginFormMotion} className='login-form__description'>
                {t('login.FILL_LOGIN_INFO')}
            </motion.p>
            <motion.div
                {...loginFooterMotion}
                className='login-form__account-login'
            >
                <p className='login-form__account-login__form__label'>
                    {t('form.EMAIL')}
                </p>
                <Input
                    {...register('email', {
                        required: true,
                        regex: emailRegex,
                        resolveError: (val, error) => {
                            if (error.type === 'regex') {
                                return `${t('general.THE')} ${t(
                                    'form.EMAIL',
                                )} ${t('form.FORMAT_INVALID')}`;
                            }
                            return `${t('form.EMAIL')} ${t(
                                'form.REQUIRED_FIELD',
                            )}`;
                        },
                    })}
                    className='login-form__account-login__form__input-field'
                />
                <p className='login-form__account-login__form__label'>
                    {t('form.PASSWORD')}
                </p>
                <PasswordInput
                    {...register('password', {
                        required: true,
                        resolveError: () => {
                            return `${t('form.PASSWORD')} ${t(
                                'form.REQUIRED_FIELD',
                            )}`;
                        },
                    })}
                    className='login-form__account-login__form__input-field'
                />

                <div className='login-form__account-login__form__remember-section'>
                    <Checkbox
                        value={remember}
                        onChange={() => setRemember(!remember)}
                    >
                        <p>{t('general.REMEMBER')}</p>
                    </Checkbox>
                    <Link
                        to='/forgot-password'
                        className='login-form__account-login__form__remember-section__link'
                    >
                        <span>{t('general.FORGOT_PASSWORD')}</span>
                    </Link>
                </div>
                <SiteButton
                    text={t('general.LOGIN')}
                    type='primary'
                    className='uppercase full-width'
                    {...registerSubmit()}
                />
                <p className='login-form__account-login__form__question'>
                    {t('general.OR')}
                </p>
                <FBLogin />
            </motion.div>
            <motion.div
                className='login-form__login-footer-message'
                {...loginFooterMotion}
            >
                <p>{t('signup.ACCOUNT_OWNER_QUESTION')}</p>
                <Link
                    to='/sign-up'
                    className='login-form__login-footer-message__link'
                >
                    <span>{t('signup.CREATE_ACCOUNT')}</span>
                </Link>
            </motion.div>
        </div>
    );
}

export default LoginForm;
