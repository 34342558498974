import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SuccessImage from 'assets/images/forms/success.png';
import './styles.scss';
import { motion } from 'framer-motion';
import SiteButton from 'components/SiteButton';
import { SignUpFormMotion } from './animation';
import SignUpForm from './SignUpForm';

function SignUpPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [accountCreated, setAccountCreated] = useState(false);

    return (
        <div className='sign-up-page'>
            {!accountCreated && (
                <SignUpForm
                    onSuccess={() => {
                        setAccountCreated(true);
                    }}
                />
            )}
            {accountCreated && (
                <motion.div
                    {...SignUpFormMotion}
                    className='sign-up-page__account-created'
                >
                    <img src={SuccessImage} alt='Success Img' />
                    <p className='sign-up-page__account-created__title'>
                        {t('signup.ACCOUNT_CREATION_SUCCESS')}
                    </p>
                    <p className='sign-up-page__account-created__description'>
                        {t('signup.CONFIRM_EMAIL')}
                    </p>
                    <SiteButton
                        text={t('general.LOGIN_INTO_ACCOUNT')}
                        type='primary'
                        className='full-width grey'
                        onClick={() => {
                            navigate('/login');
                        }}
                    />
                </motion.div>
            )}
        </div>
    );
}

export default SignUpPage;
