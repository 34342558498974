import React, { useCallback, useEffect, useState } from 'react';
import { useAccount } from 'providers/AccountProvider';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { ReactComponent as GoBack } from 'assets/icons/arrow_icon.svg';
import './styles.scss';
import moment from 'moment';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { axios } from 'startup';
import config from 'config';
import { Spin, notification } from 'antd';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { INotificationDTO, NotificationEnum } from 'DTOModels/notificationDTO';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import { notificationPageContentMotion } from './animation';

function NotificationPage() {
    const { t } = useTranslation();
    const { id } = useParams();
    const { setAccount } = useAccount();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [notificationInfo, setNotificationInfo] =
        useState<INotificationDTO>();

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const refreshPage = useCallback(() => {
        const currentDate = new Date();
        setLoading(true);
        axios
            .get(
                `${config.API_URL}/notifications/page/${id}?date=${currentDate}`,
            )
            .then((res: any) => {
                const dataInfo = res.data.Notification;
                setNotificationInfo({
                    ...dataInfo,
                });
                setLoading(false);
            })
            .catch(() => {
                // force logout
                logout();
            });
    }, []);

    // make the request everytime the notificationId changes
    useEffect(() => {
        refreshPage();
    }, [id]);

    return (
        <div className='notification-page'>
            <div className='notification-page__content'>
                <PullToRefresh
                    onRefresh={async () => refreshPage()}
                    pullingContent=''
                    resistance={1.5}
                >
                    {loading || !notificationInfo ? (
                        <div className='notification-page__content__empty'>
                            <Spin className='' size='large' />
                        </div>
                    ) : (
                        <motion.div
                            {...notificationPageContentMotion}
                            className='notification-page__content__notification'
                        >
                            <div className='notification-page__content__notification__block'>
                                <button
                                    type='button'
                                    onClick={() => {
                                        navigate('/notifications');
                                    }}
                                >
                                    <GoBack />
                                </button>
                                <div className='notification-page__content__notification__block__header'>
                                    <div className='notification-page__content__notification__block__header__county'>
                                        <div>SF</div>
                                        <p className='bold'>
                                            {notificationInfo.Type ===
                                                NotificationEnum.RegisteredOccurrence &&
                                                t(
                                                    'notifications.OCCURRENCE_REGISTERED',
                                                )}
                                            {notificationInfo.Type ===
                                                NotificationEnum.ClosedOccurrence &&
                                                t(
                                                    'notifications.OCCURRENCE_CONCLUDED',
                                                )}
                                            {notificationInfo.Type ===
                                                NotificationEnum.ReopenedOccurrence &&
                                                t(
                                                    'notifications.OCCURRENCE_REOPENED',
                                                )}
                                            {notificationInfo.Type ===
                                                NotificationEnum.NewWarning &&
                                                t('notifications.NEW_WARNING')}
                                        </p>
                                    </div>
                                    <p>
                                        {moment(
                                            notificationInfo.CreationDate,
                                        ).format('D/MM/YYYY')}
                                    </p>
                                </div>
                                <div className='notification-page__content__notification__block__body'>
                                    <p className='medium'>
                                        {t('notifications.GREETING')}
                                    </p>
                                    {notificationInfo.Type ===
                                        NotificationEnum.RegisteredOccurrence && (
                                        <p className='medium'>
                                            {t(
                                                'notifications.OCCURRENCE_REGISTERED_BODY',
                                            )}
                                        </p>
                                    )}
                                    {notificationInfo.Type ===
                                        NotificationEnum.ClosedOccurrence && (
                                        <p className='medium'>
                                            {t(
                                                'notifications.OCCURRENCE_CONCLUDED_BODY_1',
                                            )}{' '}
                                            {moment(
                                                notificationInfo.Occurrence
                                                    .CreationDate,
                                            ).format('D/MM/YYYY')}{' '}
                                            {t(
                                                'notifications.OCCURRENCE_CONCLUDED_BODY_2',
                                            )}{' '}
                                            {moment(
                                                notificationInfo.Occurrence
                                                    .CloseDate,
                                            ).format('D/MM/YYYY')}
                                            .
                                        </p>
                                    )}
                                    {notificationInfo.Type ===
                                        NotificationEnum.ReopenedOccurrence && (
                                        <p className='medium'>
                                            {t(
                                                'notifications.OCCURRENCE_CONCLUDED_BODY_1',
                                            )}{' '}
                                            {moment(
                                                notificationInfo.Occurrence
                                                    .CreationDate,
                                            ).format('D/MM/YYYY')}{' '}
                                            {t(
                                                'notifications.OCCURRENCE_REOPENED_BODY_2',
                                            )}{' '}
                                            {moment(
                                                notificationInfo.Occurrence
                                                    .CloseDate,
                                            ).format('D/MM/YYYY')}
                                            . {t('notifications.APOLOGIES')}
                                        </p>
                                    )}
                                    {notificationInfo.Type ===
                                        NotificationEnum.NewWarning &&
                                        notificationInfo.Event &&
                                        notificationInfo.Event.Eventlanguages &&
                                        notificationInfo.Event
                                            .Eventlanguages[0] && (
                                            <>
                                                <p className='medium'>
                                                    {t(
                                                        'notifications.WARNING_BODY_1',
                                                    )}
                                                </p>
                                                <p className='medium'>
                                                    {t(
                                                        'notifications.WARNING_TITLE',
                                                    )}
                                                    <Link to='/warnings'>
                                                        <span className='event-link'>
                                                            {`" ${notificationInfo.Event.Eventlanguages[0].Name} "`}
                                                        </span>
                                                    </Link>
                                                </p>
                                                <p className='medium margin'>
                                                    {t(
                                                        'notifications.WARNING_KNOW_MORE',
                                                    )}
                                                </p>
                                            </>
                                        )}
                                    {notificationInfo.Type !==
                                        NotificationEnum.NewWarning && (
                                        <p className='medium margin'>
                                            {t('notifications.THANK_YOU')}
                                        </p>
                                    )}
                                    <p className='medium'>
                                        {t('notifications.FAREWELL')}
                                    </p>
                                    <p className='medium'>
                                        {t('notifications.COUNTY')}
                                    </p>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </PullToRefresh>
            </div>
        </div>
    );
}

export default NotificationPage;
