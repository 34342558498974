import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import PageBanner from 'components/PageBanner';
import Aldermen from './components/Aldermen';

function AldermenPage() {
    const { t } = useTranslation();

    return (
        <div className='aldermen-page'>
            <PageBanner
                title={t('menu.DEPUTIES')}
                primaryPage={false}
                previous
            />
            <Aldermen />
        </div>
    );
}

export default AldermenPage;
