import LoadingPage from 'containers/LoadingPage';
import PageBackground from 'containers/PageBackground';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageProps } from './interface';
import './styles.scss';

function FullPage({ children }: FullPageProps) {
    const { ready } = useTranslation();
    const [pageReady, setPageReady] = useState(false);

    useEffect(() => {
        if (ready) {
            setTimeout(() => {
                setPageReady(true);
            }, 2000);
        }
    }, [ready]);

    if (!pageReady)
        return (
            <PageBackground>
                <LoadingPage />
            </PageBackground>
        );

    return children as JSX.Element;
}

export default FullPage;
