import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import config from 'config';
import moment from 'moment';
import { IPresidentsListItem } from './interface';

function PresidentsListItem({ president, className }: IPresidentsListItem) {
    const { t } = useTranslation();
    return (
        <div className={`presidents-list-item ${className || ''}`}>
            <div className='presidents-list-item__img'>
                <img
                    src={`${config.API_URL}/presidents/images/${president.Id}`}
                    alt='Item Img'
                />
            </div>
            <p className='presidents-list-item__title'>{president.Name}</p>
            {president.MandateEndDate && (
                <p className='presidents-list-item__description'>
                    ({moment(president.MandateStartDate).format('YYYY')}{' '}
                    {t('general.TO')}{' '}
                    {moment(president.MandateEndDate).format('YYYY')})
                </p>
            )}
            {president.IsCurrentPresident && !president.MandateEndDate && (
                <p className='presidents-list-item__description'>
                    ({t('entity.CURRENT_PRESIDENT')})
                </p>
            )}
        </div>
    );
}

export default PresidentsListItem;
